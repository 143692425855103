import * as _ from "lodash";
import "moment/locale/vi";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import ExpertCard from "../../components/expertCard";
import HistoryItem from "../../components/history-item";
import ProfileJoinedLeague from "../../components/profile/joinedLeague";
import UserCard from "../../components/userCard";
import * as SBService from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import {
  bottomBarState,
  loaderState,
  showBottomBarState,
  userLeagueSelector,
  userState,
} from "../../states/common";

const ProfilePage = () => {
  const [user, setUser] = useRecoilState(userState);
  const [userData, setUserData] = useState({});
  const [, setLoader] = useRecoilState(loaderState);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [calibrationHistory, setCalibrationHistory] = useState<any>({
    attemptsToday: 0,
    highestMilestone: 0,
    results: [],
  });
  const navigate = useNavigate();
  const [active, setActive] = useRecoilState(bottomBarState);
  const [historyTab, setHistoryTab] = useState("league");
  const [normalHistory, setNormalHistory] = useState([{}]);
  const [leagueHistory, setLeagueHistory] = useState([{}]);
  const [bottomBar, setBottomBar] = useRecoilState(showBottomBarState);
  const [experts, setExperts] = useState([{}]);
  const listJoinedLeague = useRecoilValue(userLeagueSelector);

  const [leagueMatchFilter, setLeagueMatchFilter] = useState({
    page: 1,
    limit: 10,
    hasNextPage: false,
  });

  useEffect(() => {
    document.title = "SB - Profile";
    setLoader(true);

    const getExperts = async () => {
      try {
        const expertLists = await SBService.getExperts();
        setExperts(expertLists.data);
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          switch (e.response.data.error) {
            default:
              toast.error("Lỗi, " + e.response.data.error);
              break;
          }
        } else {
          toast.error("Không thể tải danh sách Expert");
        }
      }
    };

    const loadUser = async () => {
      try {
        let dataProFile;
        const rsp = await SBService.myProfile();
        dataProFile = rsp;
        if (rsp.data) {
          dataProFile = rsp.data;
        }
        if (dataProFile) {
          if (dataProFile.profilePicture) {
            if (dataProFile.profilePicture.indexOf("https") === -1) {
              dataProFile.profilePicture = getAssetFullUrl(
                dataProFile.profilePicture
              );
            }
          } else {
            dataProFile.profilePicture = "https://placehold.co/96x96";
          }
          await loadCalibrationHistory();
          await getExperts();
          await loadLeagueMatches();
          setUser(dataProFile);
          setUserData(dataProFile);

          setLoader(false);
        } else {
          navigate("/auth/login");
        }
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          switch (e.response.data.error) {
            default:
              toast.error("Lỗi, " + e.response.data.error);
              break;
          }
        } else {
          toast.error("Không thể tải thông tin của tôi");
        }
        navigate("/auth/login");
      }
    };
    loadUser();
    setActive("profile");
    setBottomBar(true);
  }, []);

  const changeHistoryTab = (tab: string) => {
    setHistoryTab(tab);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const loadCalibrationHistory = async () => {
    try {
      let data = await SBService.getMyCalibrationHistory();
      if (data) {
        let arrResults: any[number] = [];
        _.each(data.results, (val) => {
          arrResults = arrResults.concat(val.result);
        });
        if (arrResults.length > 0) {
          // @ts-ignore
          data.highestMilestone = Math.max.apply(Math, arrResults);
        }
        setCalibrationHistory(data);
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không tìm thấy danh sách khảo hạch của tôi");
      }
    }
  };

  const loadLeagueMatches = async (page: number = 1) => {
    try {
      setLoader(true);
      const rspMatches = await SBService.getMyMatch(
        leagueMatchFilter.limit,
        page
      );
      if (rspMatches) {
        if (rspMatches.data) {
          setLeagueHistory(leagueHistory.concat(rspMatches.data));
        }
        if (rspMatches.hasNextPage) {
          setLeagueMatchFilter({
            limit: leagueMatchFilter.limit,
            page: page,
            hasNextPage: true,
          });
        } else {
          setLeagueMatchFilter({
            limit: leagueMatchFilter.limit,
            page: page,
            hasNextPage: false,
          });
        }
      }
      setLoader(false);
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể tải danh sách các trận đấu trong giải");
      }
    }
    setLoader(false);
  };

  return (
    <div className="py-6">
      <UserCard user={userData} showLogout={true} />
      {user && user.isCalibrated ? (
        <></>
      ) : (
        <div>
          <div className="w-full text-center border border-dotted rounded-[0.5rem] py-2 px-4">
            <p className="font-exo text-[#FFCA16] text-[14px]">
              Điểm và xếp hạng của bạn chưa được thẩm định. Vui lòng liên hệ các
              chuyên gia bên dưới.
            </p>
          </div>
        </div>
      )}

      <div>
        {user && user.isCalibrated ? (
          <></>
        ) : (
          <div>
            <div className="w-full bg-[#1E1E32] p-3 mt-6">
              <table className="table-auto w-full border-spacing-2">
                <thead className="text-xs uppercase font-medium text-left">
                  <tr className="h-[40px] align-top color-[#B0B4BA]">
                    <th className="font-normal" colSpan={2}>
                      Chuyên gia
                    </th>
                    <th className="font-normal text-center">Xếp hạng</th>
                    <th className="font-normal  text-center">Điểm</th>
                  </tr>
                </thead>

                {experts.length && !_.isEmpty(experts[0]) ? (
                  <tbody className="mt-4">
                    {experts.map((expert, index) => {
                      return (
                        <ExpertCard
                          key={`expert-list-${index}`}
                          data={expert}
                        />
                      );
                    })}
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="">
        {listJoinedLeague.length > 0 ? (
          <div>
            <p className="uppercase mb-2 mt-6">Giải đang tham gia</p>
            {!_.isEmpty(user.leagues) &&
              user.leagues.map((league, index) => {
                return (
                  <ProfileJoinedLeague
                    key={`profile-league-${index}`}
                    league={league}
                  />
                );
              })}
          </div>
        ) : (
          <div className="w-full bg-[#1E1E32] p-3 mt-6 flex flex-wrap justify-center items-center rounded">
            <p className="inline-block w-full text-center normal-case font-exo text-gray-11">
              Bạn chưa tham gia giải nào
            </p>
            <a
              className="inline-block btn-primary accent-color
                                 mt-4 text-center font-exo  py-1 font-sm px-4"
              onClick={(event) => navigate("/league")}
            >
              Xem giải đang diễn ra
            </a>
          </div>
        )}

        <p className="uppercase mb-2 mt-6">Lịch sử thi đấu</p>
        <div className="w-full bg-[#1E1E32] p-3 rounded">
          <div className="flex w-full">
            <ul className="w-full list-none flex text-sm font-exo history-list">
              <li
                className={`w-1/2 text-center p-2 border-b ${
                  historyTab === "league" ? "active" : ""
                }`}
                onClick={(event) => {
                  changeHistoryTab("league");
                }}
              >
                Đấu giải
              </li>
              <li
                className={`w-1/2 text-center p-2 border-b ${
                  historyTab === "normal" ? "active" : ""
                }`}
                onClick={(event) => {
                  changeHistoryTab("normal");
                }}
              >
                Đấu thường
              </li>
            </ul>
          </div>
          <div className="tab-content">
            {historyTab === "league" ? (
              <>
                {!_.isEmpty(leagueHistory) ? (
                  leagueHistory.map((match: any, index) => {
                    return !_.isEmpty(match) ? (
                      <HistoryItem
                        key={`history-me-${index}`}
                        match={match}
                        isChallenger={match.challenger.id === user.id}
                      />
                    ) : (
                      ""
                    );
                  })
                ) : (
                  <></>
                )}
                {leagueMatchFilter.hasNextPage ? (
                  <button
                    onClick={() =>
                      loadLeagueMatches(leagueMatchFilter.page + 1)
                    }
                    className="btn-primary text-white w-full  p-2 rounded-[0.5rem] overflow-hidden relative mt-4 z-0 bg-[#FF5821] text-center"
                  >
                    Tải thêm
                  </button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {historyTab === "normal" ? <></> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
