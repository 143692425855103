import LeagueListingItem from "components/league/leagueListingItem";
import moment, { ISO_8601 } from "moment";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CONSTANT } from "shared/constant";
import sportBookLogo from "statics/images/logo.png";
import Navbar from "../../components/navbar";
import * as SBService from "../../helpers/sportbookService";
import { bottomBarState, loaderState, userState } from "../../states/common";

const ScanQRSelection = (props: any) => {
  const navigate = useNavigate();
  const [active, setActive] = useRecoilState(bottomBarState);
  const { mode } = useParams();

  const [league, setLeague] = useState({
    id: null,
    name: null,
    startDate: null,
    startDateObj: moment(),
    endDate: null,
    endDateObj: moment(),
    rule: "",
    prize: "",
    logo: "",
  });

  useEffect(() => {
    setActive("qr");
    const getLeagueData = async () => {
      try {
        let leagueData;
        const rsp = await SBService.getLeagueById(CONSTANT.defaultMiniGameId);
        leagueData = rsp;
        if (rsp.data) {
          leagueData = rsp.data;
        }
        if (leagueData) {
          if (leagueData.startDate) {
            leagueData.startDateObj = moment(leagueData.startDate, ISO_8601);
            leagueData.endDateObj = moment(leagueData.endDate, ISO_8601).endOf('day');
          }
        }
        if (leagueData) {
          setLeague(leagueData);
        }
      } catch (e: any) {
        // console.log(e)
        // navigate(-1)
      }
    };

    if (!league.id) {
      getLeagueData();
    }
  }, []);

  const [, setLoader] = useRecoilState(loaderState);
  const [leagues, setLeagues] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState({
    page: 1,
    limit: CONSTANT.limit,
  });
  const [user, _] = useRecoilState(userState);

  const listJoinedId = useMemo(
    () => user.leagues.map((league) => league.id),
    [user]
  );

  const loadAllLeage = async () => {
    if ((filter.page === 1 && leagues.length === 0) || filter.page < maxPage) {
      setLoader(true);
      try {
        let data = await SBService.getLeagues(filter);
        if (data) {
          if (data.hasNextPage) {
            setMaxPage(maxPage + 1);
            setFilter({ ...filter, page: data.page });
          } else {
            setMaxPage(1);
          }
          setLeagues(leagues.concat(data.data));
        }
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          toast("Có lỗi xảy ra: " + e.response.data.error);
        } else {
          toast("Có lỗi xảy ra: " + e.message);
        }
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    setActive("league");
    loadAllLeage();
  }, []);

  return (
    <div className="">
      <Navbar
        title={
          mode == "leagues"
            ? "Các giải đang tham gia"
            : "Các hoạt động đang diễn ra"
        }
      />
      <div className="rounded w-full bg-base mt-11 py-7 px-2">
        {mode != "leagues" ? (
          <>
            <div>
              <h2 className="font-medium mt-0">ĐÁNH GIÁ TRÌNH ĐỘ</h2>
              <div className="flex items-center mt-4">
                <img className="w-[100px] h-[100px]" src={sportBookLogo} />
                <div className="w-full p-3">
                  <h3 className="text-lg">Đánh giá trình độ</h3>
                  <div
                    className="font-exo text-sm btn-primary rounded py-1 text-center w-full mt-8"
                    onClick={() => {
                      navigate("/scan-qr/ranking");
                    }}
                  >
                    QUÉT NGAY
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </>
        ) : (
          <div>
            {listJoinedId.length == 0 ? (
              <>
                <div className="w-full bg-[#16120C] border border-[#4D3000] rounded p-3">
                  <h3 className="text-[#FFE7B3]">CHƯA THAM GIA</h3>
                  <p className="inline-block w-full normal-case font-exo text-[#FFCA16]">
                    Bạn chưa tham gia giải đấu nào, tham gia ngay để bắt đầu thi
                    đấu!
                  </p>
                </div>
                <a
                  className="inline-block btn-primary accent-color w-full uppercase
                                 mt-4 text-center font-exo  py-1 font-sm px-4"
                  onClick={(event) => navigate("/league")}
                >
                  Danh sách giải đấu
                </a>
              </>
            ) : (
              <>
                <h2 className="font-medium mt-0">CÁC GIẢI ĐANG THAM GIA</h2>
                <div className="flex flex-col gap-3 mt-4">
                  {leagues.length > 0
                    ? leagues.map((league: any, index) => {
                        return (
                          listJoinedId.includes(league.id) &&
                          league.id !== CONSTANT.defaultMiniGameId && (
                            <LeagueListingItem
                              key={`league-listing-item-${index}`}
                              isShowScanBtn={true}
                              isJoined={true}
                              league={league}
                            />
                          )
                        );
                      })
                    : ""}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScanQRSelection;
