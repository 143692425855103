import React, { useState, useEffect, useReducer } from "react";
import { useRecoilState } from "recoil";
import { userState, showErrorNotificationState, errorNotificationContentState, errorNotificationTitleState, errorNotificationButtonContentState } from "../states/common";
import * as _ from "lodash";
import {
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
} from "tw-elements-react";

const GlobalNotification = () => {
    const [notification, setNotifications] = useState([]);
    const [user, setUser] = useRecoilState(userState)
    const [showModal, setShowModal] = useRecoilState(showErrorNotificationState)
    const [errorContent, setShowContent] = useRecoilState(errorNotificationContentState)
    const [errorTitle, setErrorTitle] = useRecoilState(errorNotificationTitleState)
    const [errorButtonText, setErrorButtonText] = useRecoilState(errorNotificationButtonContentState)

    const hideGlobalShowModel = () => {
        setShowModal(false)
    }

    const onHide = () => {
        setShowContent('')
        setErrorTitle('')
        setErrorButtonText('')
    }
    return (<>
        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal} onHide={onHide}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
                        <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4 border-[#4D3000] bg-[#16120C]">
                            {errorContent ? <>
                                <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">{errorTitle}</p>
                            </> : <></>}
                            <div className="font-exo text-[#FFCA16] text-[14px]">{errorContent}</div>
                        </div>
                        <div className="flex">
                            <button onClick={() => hideGlobalShowModel()}
                                className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                            > {errorButtonText ? errorButtonText : 'đã hiểu'}
                            </button>
                        </div>
                    </TEModalBody>

                </TEModalContent>
            </TEModalDialog>
        </TEModal>

    </>);
}

export default GlobalNotification