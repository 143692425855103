import * as _ from "lodash";
import { CONSTANT } from "../shared/constant";
import { callApi } from "./callApi";

export const register = (data: any) => {
  let endpoint = "/auth/register";
  const method = "POST";
  return callApi(endpoint, method, data, "v1", true);
};

export const confirmOTP = (data: any) => {
  let endpoint = "/auth/register/confirm";
  const method = "POST";
  return callApi(endpoint, method, data, "v1");
};

export const login = (data: any) => {
  let endpoint = "/auth/login";
  const method = "POST";
  return callApi(endpoint, method, data, "v1", true);
};

export const myProfile = () => {
  let endpoint = "/players/me";
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getMyCalibrationHistory = () => {
  let endpoint = "/auth/me/calibration/results";
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getUserById = (playerId: any) => {
  let endpoint = `/players/${playerId}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getLeagues = (data: any) => {
  let endpoint = `/leagues?`;
  const method = "GET";
  if (data.limit) {
    endpoint += `&limit=${data.limit}`;
  } else {
    endpoint += `&limit=${CONSTANT.limit}`;
  }
  if (data.page) {
    endpoint += `&page=${data.page}`;
  } else {
    endpoint += `&page=${data.page}`;
  }
  return callApi(endpoint, method, "", "v1");
};

export const getMiniGame = (data: any) => {
  let endpoint = `/miniGames?`;
  const method = "GET";
  if (data.limit) {
    endpoint += `&limit=${data.limit}`;
  } else {
    endpoint += `&limit=${CONSTANT.limit}`;
  }
  if (data.page) {
    endpoint += `&page=${data.page}`;
  } else {
    endpoint += `&page=${data.page}`;
  }
  return callApi(endpoint, method, "", "v1");
};

export const getLeagueById = (id: any) => {
  let endpoint = `/leagues/${id}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getCheckinListByLeagueId = (id: any, limit = 50, page = 1) => {
  let endpoint = `/leagues/${id}/checkin`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getLeaguePlayersById = (id: any, limit = 50, page = 1) => {
  let endpoint = `/leagues/${id}/participants`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getMatchCountInLeagueByCurrentWeek = (
  leagueId: string | null | undefined,
  playerId: string
) => {
  let endpoint = `/leagues/${leagueId}/played/${playerId}/week`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getExperts = (limit = 50, page = 1) => {
  let endpoint = "/players";
  const method = "GET";
  let params = {
    onlyExpert: "true",
    limit: limit,
    page: page,
  };
  return callApi(endpoint, method, params, "v1");
};

export const getRankGroupClass = (params: any = {}) => {
  let endpoint = "/ranks/groups";
  if (params) {
    endpoint = AppendGetRequestParameter(endpoint, params);
  }
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getCalibrationTypeList = () => {
  let endpoint = "/calibrations";
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const calibratePlayer = (data: any) => {
  let endpoint = "/auth/expert/calibrate";
  const method = "POST";
  return callApi(endpoint, method, data, "v1");
};

export const calibratePlayerLevel = (data: any) => {
  let endpoint = "/experts/calibrate/" + data.playerId;
  delete data.playerId;
  const method = "POST";
  return callApi(endpoint, method, data, "v1");
};

export const joinLeagueById = (leagueId: string) => {
  let endpoint = "/leagues/" + leagueId;
  const method = "POST";
  return callApi(endpoint, method, {}, "v1");
};

export const checkinLeagueById = (leagueId: string) => {
  let endpoint = "/leagues/" + leagueId + "/checkin";
  const method = "POST";
  return callApi(endpoint, method, {}, "v1");
};

export const checkoutLeagueById = (leagueId: string) => {
  let endpoint = "/leagues/" + leagueId + "/checkout";
  const method = "POST";
  return callApi(endpoint, method, {}, "v1");
};

const AppendGetRequestParameter = (url: string, params: any) => {
  if (url.indexOf("?") === -1) {
    url += "?";
  }
  if (_.isObject(params)) {
    _.forOwn(params, (val, key) => {
      url += `${key}=${val}`;
    });
  }
  return url;
};

export const getPublicLeagueById = (id: any) => {
  let endpoint = `/public/leagues/${id}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1", true);
};

export const getLeagueMatches = (id: any, limit = 50, page = 1) => {
  let endpoint = `/leagues/${id}/matches`;
  endpoint += `?limit=${limit}&page=${page}&onlyMyMatches=true`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getPlayingLeagueMatches = (id: any, limit = 50, page = 1) => {
  let endpoint = `/matches/playing`;
  endpoint += `?limit=${limit}&page=${page}&leagueId=${id}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getPlayerMatches = (id: any, limit = 50, page = 1) => {
  let endpoint = `/leagues/${id}/matches`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getPlayerInLeagueData = (leagueId: any, playerId: string) => {
  let endpoint = `/leagues/${leagueId}/participants/${playerId}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const makeDraftMatch = (leagueId: any, competitorId: any) => {
  let endpoint = "/leagues/" + leagueId + "/players/" + competitorId;
  const method = "POST";
  return callApi(endpoint, method, { status: "DRAFT" }, "v1");
};

export const makeChallenger = (matchId: string) => {
  let endpoint = `/matches/${matchId}`;
  const method = "POST";
  return callApi(endpoint, method, { status: "WAITING" }, "v1");
};

export const acceptChallenger = (matchId: string) => {
  let endpoint = `/matches/${matchId}`;
  const method = "POST";
  return callApi(endpoint, method, { status: "ACCEPTED" }, "v1");
};

export const denyChallenger = (matchId: string) => {
  let endpoint = `/matches/${matchId}`;
  const method = "POST";
  return callApi(endpoint, method, { status: "DENIED" }, "v1");
};

export const changeMatchStatus = (matchId: string, status: string) => {
  let endpoint = `/matches/${matchId}`;
  const method = "POST";
  return callApi(endpoint, method, { status: status }, "v1");
};

export const confirmResult = (
  matchId: string,
  resultId: string,
  challengerId: string,
  acceptorId: string
) => {
  let endpoint = `/matches/${matchId}/results/confirm`;
  const method = "POST";
  return callApi(
    endpoint,
    method,
    { resultId: resultId, challengerId: challengerId, acceptorId: acceptorId },
    "v1"
  );
};

export const refuseResult = (matchId: string) => {
  let endpoint = `/matches/${matchId}/results/refuse`;
  const method = "POST";
  return callApi(endpoint, method, {}, "v1");
};

export const getProfileRankingHistory = (filter: any) => {
  let endpoint = `/players/me/rank-history`;
  const method = "GET";
  return callApi(
    endpoint,
    method,
    { limit: filter.limit, page: filter.page },
    "v1"
  );
};

export const getMatchById = (matchId: string) => {
  let endpoint = `/matches/${matchId}`;
  const method = "GET";
  return callApi(endpoint, method, {}, "v1");
};

export const getMatchResultByMatchId = (matchId: string) => {
  let endpoint = `/matches/${matchId}/results`;
  const method = "GET";
  return callApi(endpoint, method, {}, "v1");
};

export const getLeagueReferees = (leagueId: any, limit = 50, page = 1) => {
  let endpoint = `/leagues/${leagueId}/referees`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, {}, "v1");
};

export const submitMatch = (matchId: string, body: object) => {
  let endpoint = `/matches/${matchId}/results`;
  const method = "POST";
  return callApi(endpoint, method, body, "v1");
};

export const getPublicLeaguePlayer = (id: any, limit = 50, page = 1) => {
  let endpoint = `/public/leagues/${id}/participants`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getPublicMatches = (id: any, limit = 50, page = 1) => {
  let endpoint = `/public/leagues/${id}/matches`;
  endpoint += `?limit=${limit}&page=${page}`;
  const method = "GET";
  return callApi(endpoint, method, "", "v1");
};

export const getMyMatch = (limit = 50, page = 1) => {
  let endpoint = `/matches`;
  const method = "GET";
  return callApi(
    endpoint,
    method,
    {
      limit: limit,
      page: page,
      onlyMyMatches: true,
    },
    "v1"
  );
};

export const leaveLeague = (leagueId: string, playerId: string) => {
  let endpoint = `/leagues/${leagueId}/participants/${playerId}`;
  const method = "DELETE";
  return callApi(endpoint, method, "", "v1");
};

export const getLeagueOpponentInfo = (leagueId: string, playerId: string) => {
  const endpoint = `/leagues/${leagueId}/bracket/${playerId}/opposite`;
  const method = "GET";

  return callApi(endpoint, method, "", "v1");
};

export const getMatchId = (leagueId: string, playerId: string) => {
  const endpoint = `/leagues/${leagueId}/players/${playerId}`;
  const method = "POST";

  return callApi(endpoint, method, { status: "DRAFT" }, "v1");
};

export const getMatchInfo = (matchId: string) => {
  const endpoint = `/matches/${matchId}`;
  const method = "GET";

  return callApi(endpoint, method, "", "v1");
};

export const getBracketLeagueInfo = (leagueId: string) => {
  const endpoint = `/leagues/${leagueId}/bracket`;
  const method = "GET";

  return callApi(endpoint, method, "", "v1");
};

export const getUserHistory = (leagueId: string, playerId: string) => {
  const endpoint = `/leagues/${leagueId}/bracket/${playerId}/history`;
  const method = "GET";

  return callApi(endpoint, method, "", "v1");
};
