import React from "react";
import Icon from "../icon";
import logo from 'statics/images/logo.png'
import moment, {ISO_8601} from "moment";
import {useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {loaderState, userState} from "../../states/common";
import toast from "react-hot-toast";
import * as SBService from "../../helpers/sportbookService";
import {getAssetFullUrl} from "../../helpers/utils";
import {CONSTANT} from "../../shared/constant";

interface MiniGameListingItemProps {
    league: any,
    isJoined: boolean,
    isShowScanBtn: boolean,
}

const MiniGameListingItem: React.FC<MiniGameListingItemProps> = ({league, isJoined = false, isShowScanBtn = false}) => {
    const navigate = useNavigate()
    league.startDateObj = moment(league.startDate, ISO_8601)
    league.endDateObj = moment(league.endDate, ISO_8601).endOf('day')
    league.startRegistrationDateObj = moment(league.startRegistrationDate, ISO_8601)
    league.endRegistrationDateObj = moment(league.endRegistrationDate, ISO_8601)
    const [user, setUser] = useRecoilState(userState)
    const [loader, setLoader] = useRecoilState(loaderState)

    const now = moment()

    const navigateToScanQR = (event: any) => {
        event.preventDefault()
    }

    const navigateToLeaguePage = () => {
        if (!isShowScanBtn) {
            if (isJoined) {
                navigate(`/league/${league.id}/detail`)
            } else {
                navigate(`/league/${league.id}`)
            }
        }
    }

    const joinLeague = async (event: any) => {
        event.preventDefault()
        setLoader(true)
        let listJoined = user.leagues.map(league => league.id)
        if (!listJoined.includes(league.id)) {
            try {
                await SBService.joinLeagueById(league.id)
                toast.success('Tham gia Mini Game thành công')
                let userLeagues = [...user.leagues, {id: league.id, banner: league.banner, logo: league.logo}]

                setUser({...user, leagues: userLeagues})
            } catch (e: any) {
                if (e.response && e.response.data && e.response.data.error) {
                    switch (e.response.data.error) {
                        case 'leagueFull':
                            toast.error('Lỗi, giải đã đủ cơ thủ tham gia')
                            break;
                        default:
                            toast.error('Lỗi, ' + e.response.data.error)
                            break;
                    }
                } else {
                    toast.error('Không thể tham gia Mini Game')
                }
            }
        } else {
            toast.error('Có lỗi xảy ra: Bạn đã tham gia Mini Game')
        }

        setLoader(false)
    }
    return <div className="w-full bg-[#1E1E32] p-3 mt-6 flex rounded" onClick={() => {
        if (isJoined) navigateToLeaguePage()
    }}>
        <div onClick={() => {
            if (!isJoined) navigateToLeaguePage()
        }}><img className="rounded w-[110px] h-[110px]"
                src={league.logo ? getAssetFullUrl(league.logo) : logo}/></div>
        <div className="ml-2 w-[calc(100%_-_110px)] mt-2">
            <div className="flex">
                <div className=" w-[calc(100%-20px)]" onClick={() => {
                    if (!isJoined) navigateToLeaguePage()
                }}>
                    <h3 className="text-xl font-medium"
                    >{league.name}</h3>
                    {/* <p className="font-light font-exo text-sm color-[#B0B4BA] mt-2">{league.startRegistrationDateObj.format('DD MMMM')} - {league.endRegistrationDateObj.format('DD MMMM')}</p> */}
                    {league.startDateObj && <div className="font-light font-exo text-sm color-[#B0B4BA] mt-2 flex"><p
                        className="w-[55px]">Thi đấu: </p>
                        <span>{league.startDateObj.format('DD/MM/YY')} - {league.endDateObj.format('DD/MM/YY')}</span>
                    </div>}

                </div>
                <div className="text-right w-[20px] flex items-center justify-end"
                     onClick={() => {
                         if (!isJoined) navigateToLeaguePage()
                     }}>
                    <Icon icon={'chevron-right'}/>
                </div>
            </div>
        </div>
    </div>
}

export default MiniGameListingItem