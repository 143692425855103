import { Outlet, useLocation } from "react-router-dom";
import Loader from "../components/loader";
import { useRecoilState } from "recoil";
import {loaderState, navbarTitleState, showNavbarState} from "../states/common";
import {useEffect} from "react";
import BottomBar from "../components/bottomBar";


const AuthLayout = () => {
    const [loader, setLoader] = useRecoilState(loaderState)
    const [navbarTitle , setNavbarTitle] = useRecoilState(navbarTitleState)
    const [showNavbar , setShowNavbar] = useRecoilState(showNavbarState)

    useEffect(() => {
        setLoader(false)
    }, [])

    return (
        <div className="main mx-auto px-4 pb-[124px] relative w-screen">
            <Outlet />
            {loader ? <Loader/> : <div/>}
        </div>
    )
};

export default AuthLayout;