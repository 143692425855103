import { CONSTANT } from "../shared/constant";

export const regexPhoneNumber = (phone: any) => {
  const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;

  return phone.match(regexPhoneNumber) ? true : false;
};

export const getAssetFullUrl = (url: string | null | undefined) => {
  if (!url) {
    return;
  }
  if (url.indexOf("https") === -1) {
    url = CONSTANT.adminAsset + url;
  }
  return url;
};

export const file2Base64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toCurrency = (string: string) => {
  const numStr = string + "";
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const currencyToNumber = (string: string) => {
  string = string + "";
  const num = Number(string.replace(/\D/g, ""));
  return num;
};

export const getRoundName = (roundName: string) => {
  return roundName === "1/64" || roundName === "1/32" || roundName === "1/16"
    ? "Vòng " + roundName
    : roundName === "1/8"
    ? "Tứ kết"
    : roundName === "1/4"
    ? "Bán kết"
    : "Chung kết";
};
