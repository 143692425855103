import Navbar from "components/navbar";
import {useLeagueDetail} from "hooks/league";
import {defaultLeague} from "interfaces/league";
import {useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {loaderState, userState} from "states/common";
import React, {useEffect, useState} from "react";
import * as SBService from "helpers/sportbookService";
import MatchDetailCard from "../../../components/league/MatchDetailCard";


const LeagueMatchHistory = () => {
    const {id = ""} = useParams();

    const league = useLeagueDetail(id, defaultLeague);
    const [user, _] = useRecoilState(userState);
    const [, setLoader] = useRecoilState(loaderState);

    const [matches, setMatches] = useState<any>([])
    const [leagueMatchFilter, setLeagueMatchFilter] = useState({
        page: 0,
        limit: 50,
        hasNextPage: false,
    });

    useEffect(() => {
      loadData()
    }, []);

    const loadData = async () => {
        setLoader(true)
        const page = leagueMatchFilter.page + 1;
        const rspMatches = await SBService.getPlayerMatches(id, leagueMatchFilter.limit, page)
        if (matches.length > 0) {
            setMatches(matches.concat(rspMatches.data))
        } else {
            setMatches(rspMatches.data)
        }
        if (rspMatches.hasNextPage) {
            setLeagueMatchFilter({
                limit: leagueMatchFilter.limit,
                page: page,
                hasNextPage: true,
            });
        } else {
            setLeagueMatchFilter({
                limit: leagueMatchFilter.limit,
                page: page,
                hasNextPage: false,
            });
        }
        setLoader(false)
    }


    return (
        <div>
            <Navbar title="LỊCH SỬ THI ĐẤU"/>
            {league && league.id && <h1 className="text-[30px] mt-3 text-center">{league.name}</h1>}
            {matches.length > 0 && <div className="w-full bg-[#1E1E32] p-3 rounded mt-2">
                <div className="bg-[#1E1E32] text-[14px] text-[#B0B4BA] mb-6 rounded-lg"
                     style={{background: '#1E1E32', color: '#B0B4BA'}}>
                    <div className="flex mb-3 w-full font-bold px-3 pt-3">
                        <div className="w-8/12">Cơ thủ</div>
                        <div className="w-2/12 text-center">Hạng</div>
                        <div className="w-2/12 text-center">kết quả</div>
                    </div>
                    <hr className="border-[#5E5E5E] "/>
                    <div className="pl-2 ">
                        {matches.length > 0 ? (matches.map((match: any, index: number) => {
                            return <MatchDetailCard key={`match-league-${index}`} match={match}/>
                        })) : ''}
                    </div>
                    {leagueMatchFilter.hasNextPage ? (
                        <button
                            onClick={() =>
                                loadData()
                            }
                            className="btn-primary text-white w-full  p-2 rounded-[0.5rem] overflow-hidden relative mt-4 z-0 bg-[#FF5821] text-center"
                        >
                            Tải thêm
                        </button>
                    ) : (
                        <></>
                    )}

                </div>
            </div>}

        </div>
    );
};

export default LeagueMatchHistory;
