import { usePlayerList } from "hooks/user";
import { UserInterface } from "interfaces/user";
import {getAssetFullUrl} from "../../../../helpers/utils";

const UserRow = ({ user }: { user: UserInterface }) => {
  return (
    <div className="grid grid-cols-12 px-12 py-2 border-t-[0.5px] border-[#5A6169]">
      <div className="col-span-10 flex items-start gap-2">
        <div className="w-[28px] h-[28px] rounded-full overflow-hidden">
          <img
            src={getAssetFullUrl(user?.player?.profilePicture)}
            alt="user_pic"
            width={28}
            height={28}
          />
        </div>
        <div className="text-[14px] text-[#B0B4BA]">{user?.player?.name}</div>
      </div>

      <div className="col-span-2 text-[14px] font-light text-[#B0B4BA] text-center">
        {user?.player?.ranking?.class}
      </div>
    </div>
  );
};

export const UpcomingLeagueUserList = ({ leagueID }: { leagueID: string }) => {
  const players = usePlayerList(leagueID);

  return (
    <section className="py-3 px-2 mt-2 bg-[#1E1E32]" id="user-list">
      <div className="grid grid-cols-12 px-12 py-2">
        <div className="col-span-10 items-center text-start text-[12px] text-[#D9D9D9] font-semibold font-oswald">
          CƠ THỦ
        </div>
        <div className="col-span-2 items-start text-center text-[12px] text-[#D9D9D9] font-semibold font-oswald">
          HẠNG
        </div>
      </div>
      {players?.map((item, id) => (
        <UserRow key={`user-row-${id}`} user={item} />
      ))}
    </section>
  );
};
