import React from "react";
import firstRank from "statics/images/first-rank.svg"
import secondRank from "statics/images/second-rank.svg"
import thirdRank from "statics/images/third-rank.svg"


interface RankIconItemProps {
    rank: number,
    isLeft: boolean
}

const RankIconItem: React.FC<RankIconItemProps> = ({rank, isLeft = true}) => {

    const getRankIcon = (rank: number) => {
        switch (rank) {
            case 1:
                return <img className="" src={firstRank}/>
            case 2:
                return <img className="" src={secondRank}/>
            case 3:
                return <img className="" src={thirdRank}/>
            default:
                return <span className={isLeft ?'pl-3' : ''}>{rank || '--'}</span>
        }
    }
    return <div className="">
        { getRankIcon(rank) }
    </div>
}

export default RankIconItem