import React from 'react';
import {RouterProvider} from "react-router-dom";
import {router} from "./router";
import Loader from "./components/loader";
import { Toaster } from 'react-hot-toast';
import "tw-elements-react/dist/css/tw-elements-react.min.css";

function App() {
    return (
            <div className="App">
                <RouterProvider fallbackElement={<Loader/>} router={router}/>
                <Toaster />
            </div>
    );
}

export default App;
