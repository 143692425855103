import {IconName, library} from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

library.add(fas)

const Icon = (props: any) => {
    // @ts-ignore
    return <FontAwesomeIcon icon={["fas", props.icon]} size={props.size}/>
}

export default Icon