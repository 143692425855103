import * as SBService from "helpers/sportbookService";
import { MatchInterface } from "interfaces/match";
import {
  EliminateUserInterface,
  LeagueUserInterface,
  UserByIdInterface,
  UserHistoryInterface,
  UserInterface,
} from "interfaces/user";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export const usePlayerList = (leagueId: string) => {
  const [players, setPlayers] = useState<UserInterface[]>([]);
  const [curPage, setCurPage] = useState(0);
  const [hasNext, shouldLoadNext] = useState(false);

  const fetcher = async (p = 1, ps = 50) => {
    try {
      const leaguePlayers = await SBService.getLeaguePlayersById(
        leagueId,
        ps,
        p
      );

      if (p === 1) {
        setPlayers(leaguePlayers.data);
      } else {
        setCurPage(p);
        setPlayers(players.concat(leaguePlayers.data));
      }
      if (leaguePlayers.hasNextPage) {
        shouldLoadNext(leaguePlayers.hasNextPage);
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        return toast.error("Lỗi, " + e.response.data.error);
      }

      return toast.error("Không thể tải danh sách cơ thủ tham gia giải đấu");
    }
  };

  useEffect(() => {
    if (hasNext || !curPage) {
      fetcher(curPage + 1);
    }
  }, [curPage, hasNext]);

  return players;
};

export const useLeagueUser = (leagueId: string, userId: string) => {
  const [user, setUser] = useState<LeagueUserInterface>();

  const fetcher = async () => {
    try {
      const userRec = await SBService.getPlayerInLeagueData(leagueId, userId);
      setUser(userRec?.data);
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        return toast.error("Lỗi, " + e.response.data.error);
      }

      return toast.error("Không thể tải thông tin cơ thủ tham gia giải đấu");
    }
  };

  useEffect(() => {
    (async () => await fetcher())();
  }, []);

  return user;
};

export const useMatchInfo = (leagueId: string, acceptor: string, matchId: string = '') => {
  const [match, setMatch] = useState<MatchInterface>();

  const fetcher = async () => {
    try {
      if (matchId) {
        const matchData = await SBService.getMatchInfo(matchId);
        setMatch(matchData?.data);
      } else {
        if (!leagueId || !acceptor) return;
        const matchIdData = await SBService.getMatchId(leagueId, acceptor);
        const newMatchId = matchIdData?.data?.id;

        const matchData = await SBService.getMatchInfo(newMatchId);
        setMatch(matchData?.data);
      }

    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        return toast.error("Lỗi, " + err.response.data.error);
      }

      return toast.error("Không thể tải thông tin trận đấu");
    }
  };

  useEffect(() => {
    (async () => await fetcher())();
  }, [leagueId, acceptor, matchId]);

  return match;
};

export const useEliminatePair = (leagueId: string, userId: string) => {
  const [info, setInfo] = useState<EliminateUserInterface[]>([]);

  const fetcher = async () => {
    try {
      const data = await SBService.getLeagueOpponentInfo(leagueId, userId);
      setInfo(data?.data);
    } catch (err: any) {
      if (err?.response?.status === 428) return;
      if (err.response && err.response.data && err.response.data.error) {
        return toast.error("Lỗi, " + err.response.data.error);
      }

      return toast.error("Không thể tải thông tin cặp đấu loại trực tiếp");
    }
  };

  useEffect(() => {
    (async () => await fetcher())();
  }, []);

  return info;
};

export const useUserByID = (id: string) => {
  const [user, setUser] = useState<UserByIdInterface>();

  const fetcher = async () => {
    if (!id) return;

    try {
      const data = await SBService.getUserById(id);
      setUser(data.data);
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        return toast.error("Lỗi, " + err.response.data.error);
      }

      return toast.error("Không thể tải thông tin cặp đấu loại trực tiếp");
    }
  };

  useEffect(() => {
    (async () => await fetcher())();
  }, [id]);

  return user;
};

export const useUserHistory = (leagueId: string, playerId: string) => {
  const [history, setHistory] = useState<UserHistoryInterface[]>([]);

  const fetcher = async () => {
    try {
      if (!leagueId || !playerId) return;
      const data = await SBService.getUserHistory(leagueId, playerId);
      setHistory(data?.data);
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        return toast.error("Lỗi, " + err.response.data.error);
      }

      return toast.error("Không thể tải thông tin lịch sử đấu");
    }
  };

  useEffect(() => {
    (async () => await fetcher())();
  }, [leagueId, playerId]);

  return history;
};
