import React, { useEffect } from "react";
import { getAssetFullUrl } from "../../helpers/utils";
import Fancybox from "components/fancybox";
import moment from "moment";

interface MatchDetailCardProps {
    match: any
}

const MatchDetailCard: React.FC<MatchDetailCardProps> = ({ match }) => {
    return <div className="h-fit pt-2 pb-2 font-semibold" style={{borderBottom:"1px solid #5E5E5E"}}>
        <div className="text-[#777B84] font-normal font-[12px]" style={{color: '#777B84'}}>{match.endedAt ? moment(match.endedAt).format('DD/MM/YYYY HH:mm') : ''}</div>
        <div className="flex h-[28px] flex-wrap items-center w-full mb-1">
            <div className="w-[64%] flex items-center">
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(match.challenger.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(match.challenger.profilePicture)} />
                    </a>
                </Fancybox>
                <span className="text-sm pl-2">{match.challenger.name}</span>
            </div>

            <span className="w-[18%] text-center ">{match.challenger.ranking.class}</span>
            <span className="w-[18%] text-center text-[#0BD8B6]" style={{color: '#0BD8B6'}}>{match.challengerPoint}</span>

        </div>
        <div className="flex h-[28px] flex-wrap items-center w-full">
            <div className="w-[64%] flex items-center">
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(match.acceptor.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(match.acceptor.profilePicture)} />
                    </a>
                </Fancybox>
                <span className="text-sm pl-2">{match.acceptor.name}</span>
            </div>

            <span className="w-[18%] text-center">{match.acceptor.ranking.class}</span>
            <span className="w-[18%] text-center text-[#EB9091]" style={{color: '#EB9091'}}>{match.acceptorPoint}</span>

        </div>
    </div>
}

export default MatchDetailCard