import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import Loader from "../../components/loader";
import { useRecoilState } from "recoil";
import { loaderState, showBottomBarState, userState } from "../../states/common";
import * as SBService from "../../helpers/sportbookService";
import * as _ from "lodash";

import toast from "react-hot-toast";
import { Slider } from "@mui/material";
import Icon from "../../components/icon";
import { TECollapse, TERipple } from "tw-elements-react";
import { getAssetFullUrl } from "../../helpers/utils";
import {CONSTANT} from "../../shared/constant";


interface rankClassGroupInterface {
    id: string,
    code: string,
    description: string
}

const RankingUser = () => {
    const [rankClassGroup, setRankClassGroup] = useState<[rankClassGroupInterface]>();
    const [selectedRankClassGroup, setSetlectedRankClassGroup] = useState<any>({})
    const navigate = useNavigate();
    const { id } = useParams()
    const [player, setPlayer] = useState<any>({})
    const [loader, setLoader] = useRecoilState(loaderState)
    const [, setBottomBar] = useRecoilState(showBottomBarState)
    const [showDescription, setShowDescription] = useState(false);
    const toggleShowDescription = () => setShowDescription(!showDescription);

    const [skillScore, setSkillScore] = useState(50);
    const [strategyScore, setStrategyScore] = useState(50);
    const [mentalityScore, setMentalityScore] = useState(50);
    const [note, setNote] = useState("");

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 25,
            label: '25',
        },
        {
            value: 50,
            label: '50',
        },
        {
            value: 75,
            label: '75',
        },
        {
            value: 100,
            label: '100',
        },
    ];

    useEffect(() => {
        setBottomBar(false)
        const loadDefaultData = async () => {
            try {
                setLoader(false)
                let userData
                const rsp = await SBService.getUserById(id)
                userData = rsp
                if (rsp.data) {
                    userData = rsp.data
                }
                if (userData) {
                    if (userData.profilePicture) {
                        if (userData.profilePicture.indexOf('https') === -1) {
                            userData.profilePicture = getAssetFullUrl(userData.profilePicture)
                        }
                    } else {
                        userData.profilePicture = 'https://placehold.co/96x96'
                    }
                    setPlayer(userData)
                }
                const rspDataRankGroupClass = await SBService.getRankGroupClass({ limit: 50 })
                if (rspDataRankGroupClass && rspDataRankGroupClass.data) {
                    setRankClassGroup(rspDataRankGroupClass.data)
                }
            } catch (e: any) {
                if (e.response && e.response.data && e.response.data.error) {
                    switch (e.response.data.error) {
                        default:
                            toast.error('Lỗi, ' + e.response.data.error)
                            break;
                    }
                } else {
                    toast.error('Không tìm thấy thông tin cơ thủ')
                }
                setLoader(false)
                navigate('/scan-qr/ranking')
            }
        }
        if (id) {
            setLoader(true)
            loadDefaultData()
        } else {
            toast.error('Lỗi, tài khoản không thể thực hiện khảo sát!')
            navigate('/')
        }
    }, [])

    const updateSkillScore = (e: any) => {
        setSkillScore(Number(e.target.value))
    }

    const changeSkillScore = (type: string) => {
        let newSkillScore
        if (type === 'minus') {
            newSkillScore = skillScore - 1;
        } else {
            newSkillScore = skillScore + 1;
        }
        setSkillScore(validateScorePoint(newSkillScore))
    }

    const updateStrategyScore = (e: any) => {
        setStrategyScore(Number(e.target.value))
    }

    const changeStrategyScore = (type: string) => {
        let newSkillScore
        if (type === 'minus') {
            newSkillScore = strategyScore - 1;
        } else {
            newSkillScore = strategyScore + 1;
        }
        setStrategyScore(validateScorePoint(newSkillScore))
    }

    const updateMentalityScore = (e: any) => {
        setMentalityScore(Number(e.target.value))
    }

    const changeMentalityScore = (type: string) => {
        let newSkillScore
        if (type === 'minus') {
            newSkillScore = mentalityScore - 1;
        } else {
            newSkillScore = mentalityScore + 1;
        }
        setMentalityScore(validateScorePoint(newSkillScore))
    }

    const validateScorePoint = (score: number) => {
        if (score < 0) {
            score = 0;
        }
        if (score > 100) {
            score = 100;
        }
        return score;
    }

    const changeSelectedGroupEvent = (event: any) => {
        const selectedValue = event.target.value
        if (!selectedValue) {
            setSetlectedRankClassGroup({ id: "", code: "", description: "" })
        } else {
            const selectedData = _.find(rankClassGroup, { id: selectedValue })
            setSetlectedRankClassGroup(selectedData)
        }
        setShowDescription(false)
    }

    const confirmRankData = async (event: any) => {
        event.preventDefault()
        const formData = {
            playerId: player.id,
            technicalLevel: skillScore,
            strategyLevel: strategyScore,
            mentalityLevel: mentalityScore,
            rankClassId: selectedRankClassGroup.id,
            note: note,
            miniGameId: CONSTANT.defaultMiniGameId
        }

        try {
            setLoader(true)
            await SBService.calibratePlayerLevel(formData)
            toast.success('Khảo sát người chơi thành công!')
            navigate('/')
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    case 'notExpert':
                        toast.error('Lỗi, tài khoản thao tác không phải là chuyên gia đánh giá')
                        break;
                    case 'canNotSelfCalibrate':
                        toast.error('Lỗi, không thể tự đánh giá chính mình')
                        break;
                    case 'playerNotFound':
                        toast.error('Lỗi, không tìm thấy người chơi được khảo sát')
                        break;
                    case 'alreadyCalibrated':
                        toast.error('Lỗi, Người chơi đã được khảo sát trình độ')
                        break;
                    default:
                        toast.error('Lỗi, tài khoản không thể thực hiện khảo sát, xin vui lòng thử lại sau')
                }
            } else {
                toast.error('Lỗi, tài khoản không thể thực hiện khảo sát, xin vui lòng thử lại sau')
            }

        }

    }

    return <div className="">
        <Navbar title={'Đánh giá cơ thủ'} />
        <div className="w-full flex flex-wrap justify-center items-center py-4">
            <div className="flex w-full items-center space-x-4">
                <div className="flex-shrink-0">
                    <img className="w-[96px] rounded-full" src={player.profilePicture}
                        alt="" />
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-lg font-medium truncate">
                        {player.name}
                    </p>
                    <p className="text-sm truncate mt-3 text-white opacity-65">
                        {player.phone}
                    </p>
                    <p className="text-sm truncate text-white opacity-65">
                        {player.email}
                    </p>
                </div>
            </div>
            <div className="w-full rounded bg-base py-7 px-3 mt-4">
                <div>
                    <h2 className="font-medium uppercase mb-2">CHỌN TRÌNH ĐỘ</h2>
                    <select className="w-full p-2 rounded text-[#363A3F] font-exo text-sm mb-2" onChange={changeSelectedGroupEvent} required>
                        <option value="">-- Trình độ --</option>
                        {rankClassGroup?.map((rankGroup, key) => {
                            return <option key={`rank_group_${key}`} value={rankGroup.id}>{rankGroup.code}</option>
                        })}
                    </select>
                    <TERipple className="w-full" rippleColor="" onClick={() => toggleShowDescription()}>
                        <h4 className="inline-block w-full main-color font-exo"> {showDescription ? <Icon icon={'chevron-down'} /> : <Icon icon={'chevron-right'} />} <span className="ml-2">Mô tả trình độ</span></h4>
                    </TERipple>
                    <TECollapse
                        className="block rounded-lg flex flex-wrap w-full" show={showDescription}>
                        <div dangerouslySetInnerHTML={{ __html: selectedRankClassGroup ? selectedRankClassGroup.description : `` }} />
                    </TECollapse>
                </div>

                <div className="mt-6">
                    <h3 className="font-exo text-sm mb-2">Kỹ thuật</h3>
                    <div className="flex w-full">
                        <div className="w-1/3"> <TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] text-[#5A6169] px-3 py-2 rounded" onClick={() => { changeSkillScore('minus') }}><Icon icon={'minus'} /></span></TERipple></div>
                        <div className="w-1/3 flex justify-center items-center font-exo text-sm">{skillScore}%</div>
                        <div className="w-1/3 flex justify-end"><TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] px-3 py-2 text-[#5A6169] px-1 rounded" onClick={() => { changeSkillScore('add') }}><Icon icon={'plus'} /></span></TERipple></div>
                    </div>
                    <div className="custom-marker">
                        <Slider
                            value={skillScore}
                            step={1}
                            min={0}
                            max={100}
                            marks={marks}
                            onChange={updateSkillScore}
                        />
                    </div>
                </div>

                <div className="mt-6">
                    <h3 className="font-exo text-sm mb-2">Chiến Thuật</h3>
                    <div className="flex w-full">
                        <div className="w-1/3"> <TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] text-[#5A6169] px-3 py-2 rounded" onClick={() => { changeStrategyScore('minus') }}><Icon icon={'minus'} /></span></TERipple></div>
                        <div className="w-1/3 flex justify-center items-center font-exo text-sm">{strategyScore}%</div>
                        <div className="w-1/3 flex justify-end"><TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] px-3 py-2 text-[#5A6169] px-1 rounded" onClick={() => { changeStrategyScore('add') }}><Icon icon={'plus'} /></span></TERipple></div>
                    </div>
                    <div className="custom-marker">
                        <Slider
                            value={strategyScore}
                            step={1}
                            min={0}
                            max={100}
                            marks={marks}
                            onChange={updateStrategyScore}
                        />
                    </div>
                </div>

                <div className="mt-6">
                    <h3 className="font-exo text-sm mb-2">Tâm lý thi đấu</h3>
                    <div className="flex w-full">
                        <div className="w-1/3"> <TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] text-[#5A6169] px-3 py-2 rounded" onClick={() => { changeMentalityScore('minus') }}><Icon icon={'minus'} /></span></TERipple></div>
                        <div className="w-1/3 flex justify-center items-center font-exo text-sm">{mentalityScore}%</div>
                        <div className="w-1/3 flex justify-end"><TERipple rippleColor="" ><span className="inline-block bg-[#D9D9D9] px-3 py-2 text-[#5A6169] px-1 rounded" onClick={() => { changeMentalityScore('add') }}><Icon icon={'plus'} /></span></TERipple></div>
                    </div>
                    <div className="custom-marker">
                        <Slider
                            value={mentalityScore}
                            step={1}
                            min={0}
                            max={100}
                            marks={marks}
                            onChange={updateMentalityScore}
                        />
                    </div>
                </div>

                <div className="mt-6">
                    <h3 className="font-exo text-sm mb-2">Ghi chú</h3>
                    <textarea className="w-full rounded text-[#000] p-2" onChange={(e) => setNote(e.target.value)}></textarea>
                </div>
                <div className="mt-6">
                    <a
                        onClick={(e) => {
                            confirmRankData(e)
                        }}
                        className="w-full ml-1 px-6 py-4 inline-block rounded  background-accent text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow text-white"
                    >
                        Xác nhận
                    </a>
                    <div className="w-full text-center mt-3">
                        <a className="text-accent" onClick={(e) => { e.preventDefault(); navigate('/') }}>Hủy</a>
                    </div>

                </div>
            </div>
        </div>


        {loader ? <Loader /> : <div />}
    </div>
}

export default RankingUser