import {LeaguePrizeInterface} from "../interfaces/league";


export const findPrize = (position: number, leaguePrize: Array<LeaguePrizeInterface> | null | undefined) => {
  if (leaguePrize) {
    for (let i = 0; i < leaguePrize.length; i++) {
      if (leaguePrize[i].label === position.toString()) {
        return leaguePrize[i];
      }
    }

    return leaguePrize[leaguePrize.length - 1];
  }
  return undefined;

};
