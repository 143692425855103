import React, { useEffect } from "react";
import { getAssetFullUrl } from "../../helpers/utils";
import Fancybox from "components/fancybox";
import RankIconItem from "./RankItem";

interface RankPlayerCardProps {
    player: any,
    index: number
}

const RankPlayerCard: React.FC<RankPlayerCardProps> = ({ player, index }) => {

    return <div className="h-[49px] text-center flex font-semibold " style={{ borderBottom: "1px solid #5E5E5E" }}>
        <div className="flex flex-wrap items-center w-full">
            <div className="w-[30%] flex items-center">
                <div className="w-1/2 flex items-center justify-center pl-3">
                    <RankIconItem rank={index + 1} isLeft={false} />
                </div>
                <div className="w-1/2">
                    <span>{player.won * 3 + player.drew}</span>
                </div>
            </div>
            <div className="w-[60%] flex items-center pl-2">
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(player?.player?.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(player?.player?.profilePicture)} />
                    </a>
                </Fancybox>
                <span className="text-sm pl-2">{player?.player?.name}</span>
            </div>

            <span className="w-[10%] text-left mb-2">{player?.player?.ranking ? player.player.ranking?.class : "--"}</span>
        </div>
    </div>
}

export default RankPlayerCard