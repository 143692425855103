import {QrScanner} from "@yudiel/react-qr-scanner";
import React, {useEffect, useReducer, useState} from "react";
import {Outlet, redirect, useNavigate, useParams} from "react-router-dom";
import Navbar from "../components/navbar";
import BottomBar from "../components/bottomBar";
import Loader from "../components/loader";
import {useRecoilState} from "recoil";
import {loaderState, showBottomBarState, userState} from "../states/common";
import * as SBService from "../helpers/sportbookService";
import * as _ from "lodash";
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TEModalFooter,
} from "tw-elements-react";
import Icon from "../components/icon";
import toast from "react-hot-toast";
import {getAssetFullUrl} from "../helpers/utils";

const CalibrateUser = () => {
    const [showModal, setShowModal] = useState(false);
    const [showModalContinue, setShowModalContinue] = useState(false);
    let [scoreChange, updateScoreChange] = useState(0);
    const navigate = useNavigate();
    const {id} = useParams()
    const [player, setPlayer] = useState({
        id: id,
        profilePicture: '',
        name: '',
        phone: '',
        email: '',
    })
    const [user, setUser] = useRecoilState(userState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [isUnderstood, setIsUnderstood] = useState(false)
    const [secondRuleTxt, setSecondRuleTxt] = useState("")
    const [bottomBar, setBottomBar] = useRecoilState(showBottomBarState)
    let [calibationList, setCalibrationList] = useState([])
    let [isDisableSecondRound, setIsDisableSecondRound] = useState(true)

    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    const [currentTurn, setCurrentTurn] = useState(0);

    const [score, setScore] = useState({
        firstScore: 0,
        secondScore: 0,
        thirdScore: 0,
        forthScore: 0,
        fifthScore: 0,
        sixthScore: 0,
    })


    useEffect(() => {
        setBottomBar(false)
        const loadPlayerById = async () => {
            try {                
                setLoader(false)
                let userData
                const rsp = await SBService.getUserById(id)    
                userData = rsp
                if(rsp.data){
                    userData = rsp.data
                }        
                if (userData) {
                    if(userData.profilePicture) {
                        if (userData.profilePicture.indexOf('https') === -1) {
                            userData.profilePicture = getAssetFullUrl(userData.profilePicture)
                        }
                    } else {                        
                        userData.profilePicture = 'https://placehold.co/96x96'
                    }
                    setPlayer(userData)
                    const dataCalibration = await SBService.getCalibrationTypeList()
                    setCalibrationList(dataCalibration)
                } else {
                    navigate('/scan-qr')
                }
            } catch (e: any) {
                setLoader(false)
                navigate('/scan-qr')
            }

        }
        if (id && user.isExpert) {
            setLoader(true)
            loadPlayerById()
        } else  {
            toast('Lỗi, tài khoản không thể thực hiện khảo sát')
            navigate('/')
        }
    }, [])

    const submitScoreHandler = async () => {
        let resultData = _.map(score, (val) => {
            return val
        })
        let submitData = {
            calibrationId: '9a828a94-db54-47d7-a80f-000d80031b1c',
            venueId: '9a5255c2-7248-4f46-94d7-1f2dd6fabeb2',
            result: resultData,
            playerId: player.id
        }
        try {
            const data = await SBService.calibratePlayer(submitData)
            if (data) {
                setShowModal(true)
            }
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                toast('Có lỗi xảy ra: ' + e.response.data.error)
            } else {
                toast('Có lỗi xảy ra: ' + e.message)
            }

        }

        return
    }

    const updateScorePoint = async (event: any, label: string, indexOfTurn: number = 0) => {
        let newScore = score
        let newAttemptScore = 0
        if (event.target.value) {
            newAttemptScore = Number(event.target.value)
        }
        if (indexOfTurn === 3) {
            if (newAttemptScore < 15) {
                setSecondRuleTxt("4 mạng mỗi lần thử")
            }
        }
        if (indexOfTurn === 4) {
            if (newAttemptScore < 15) {
                setSecondRuleTxt("5 mạng mỗi lần thử")
            }
        }
        _.update(newScore, label, () => newAttemptScore)
        setScore(newScore)
        if (newAttemptScore >= 15) {
            setShowModalContinue(true)
        }
        updateScoreChange(scoreChange + 1)

    }
    // logic if total score 1,2,3 lt 24 ==> enable 4,5,6
    // if 1,2,3 lt 9 ==> text of 4,5,6 ==> "5 mạng mỗi lần thử"
    // if 1,2,3 lt 15 ==> text of 4,5,6 = "4 mạng mỗi lần thử"
    // if 1,2,3 lt 24 ==> text of 4,5,6 = "3 mạng mỗi lần thử"
    const checkChangeSCore = () => {
        if (score.firstScore && score.secondScore && score.thirdScore && score.firstScore < 15 && score.secondScore < 15 && score.thirdScore < 15) {
            setIsDisableSecondRound(false)
            if (!score.forthScore) {
                setSecondRuleTxt("3 mạng mỗi lần thử")
            }

        } else {
            setIsDisableSecondRound(true)
            setSecondRuleTxt("")
        }
    }

    useEffect(checkChangeSCore, [scoreChange])

    return <div className="">
        <Navbar title={'Khảo hạch'}/>
        <div className="w-full flex flex-wrap justify-center items-center py-4">
            <div className="flex w-full items-center space-x-4">
                <div className="flex-shrink-0">
                    <img className="w-[96px] rounded-full" src={player.profilePicture}
                         alt=""/>
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-lg font-medium truncate">
                        {player.name}
                    </p>
                    <p className="text-sm truncate mt-3 text-white opacity-65">
                        {player.phone}
                    </p>
                    <p className="text-sm truncate text-white opacity-65">
                        {player.email}
                    </p>
                </div>
            </div>

            <div className="w-full mt-4 bg-[#1E1E32] rounded py-3 px-12">
                <h3 className="text-center uppercase">LƯỢT CHƠI ĐẦU TIÊN</h3>
                <p className="text-center mb-2 text-sm">02 mạng mỗi lần thử</p>
                <div className="w-full mt-1 flex justify-center">
                    <input disabled={!((score.forthScore + score.fifthScore + score.sixthScore) == 0)} type="number"
                           className=" text-center h-[48px] mr-1 bg-main rounded w-[48px]"
                           onChange={(e) => updateScorePoint(e, 'firstScore', 0)}/>
                    <input disabled={!((score.forthScore + score.fifthScore + score.sixthScore) == 0)} type="number"
                           className=" text-center h-[48px] mx-1 bg-main rounded w-[48px]"
                           onChange={(e) => updateScorePoint(e, 'secondScore', 1)}
                    />
                    <input disabled={!((score.forthScore + score.fifthScore + score.sixthScore) == 0)} type="number"
                           className=" text-center h-[48px] ml-1 bg-main rounded w-[48px]"
                           onChange={(e) => updateScorePoint(e, 'thirdScore', 2)}
                    />
                </div>
                <div className="w-full mt-4 flex text-sm font-light justify-center font-exo">
                    <span className="block w-[48px] text-left">Lần 1</span>
                    <span className="block w-[48px] text-center">Lần 2</span>
                    <span className="block w-[48px] text-right">Lần 3</span>
                </div>
            </div>
            <div className="w-full mt-4 bg-[#1E1E32] rounded py-3 px-12">
                <h3 className="text-center uppercase">LƯỢT CHƠI VỚT</h3>
                {!isDisableSecondRound ? <p className="text-center mb-2 text-sm">{secondRuleTxt}</p> : <></>}
                <div className="w-full">
                    <div className="w-full mt-1 flex justify-center">
                        <input disabled={isDisableSecondRound || !score.thirdScore} type="number"
                               className={`text-center h-[48px] ml-1  rounded w-[48px] ${isDisableSecondRound || !score.thirdScore ? 'bg-[#5A6169]' : 'bg-main'}`}
                               onChange={(e) => updateScorePoint(e, 'forthScore', 3)}
                        />
                        <input disabled={isDisableSecondRound || !score.forthScore || score.forthScore >= 15} type="number"
                               className={`text-center h-[48px] ml-1  rounded w-[48px] ${isDisableSecondRound || !score.forthScore || score.forthScore >= 15 ? 'bg-[#5A6169]' : 'bg-main'}`}
                               onChange={(e) => updateScorePoint(e, 'fifthScore', 4)}
                        />
                        <input disabled={isDisableSecondRound || !score.fifthScore || score.fifthScore >= 15} type="number"
                               className={`text-center h-[48px] ml-1  rounded w-[48px] ${isDisableSecondRound || !score.fifthScore || score.fifthScore >= 15 ? 'bg-[#5A6169]' : 'bg-main'}`}
                               onChange={(e) => updateScorePoint(e, 'sixthScore', 5)}
                        />
                    </div>
                    <div className="w-full mt-4 flex text-sm font-light justify-center font-exo">
                        <span className="block w-[48px] text-left">Lần 4</span>
                        <span className="block w-[48px] text-center">Lần 5</span>
                        <span className="block w-[48px] text-right">Lần 6</span>
                    </div>
                </div>
                <div className="w-full flex fixed bottom-0 left-0 py-2 px-8 bg-[#151522]">
                    <button
                        className="block w-1/2 h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow"
                        onClick={(e) => {
                            submitScoreHandler()
                        }}>Xác nhận
                    </button>
                    <button
                        className="block w-1/2 ml-[10%] h-12 btn-info text-center uppercase text-sm border border-gray-200"
                        onClick={(e) => {
                            navigate('/')
                        }}>Trở về
                    </button>
                </div>


            </div>

        </div>
        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center">Khảo hạch thành công</TEModalBody>
                    <TEModalFooter className="bg-[#1E1E32] flex justify-center">
                        <TERipple rippleColor="light">
                            <button
                                type="button" onClick={() => {
                                navigate('/')
                            }}
                                className="ml-1 px-6 py-4 inline-block rounded  bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow"
                            >
                                Đồng ý và quay về
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>


        {loader ? <Loader/> : <div/>}
    </div>
}

export default CalibrateUser