import {useRecoilState} from "recoil";
import {userState} from "../states/common";
import * as _ from "lodash";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const UserCard = (props: any) => {
    const {user} = props
    const navigate = useNavigate()

    return <div className="w-full">
        <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
                <img className="w-[92px] h-[92px] object-cover object-center rounded-full" src={user.profilePicture}
                     alt=""/>
            </div>
            <div className="flex-1 min-w-0 mt-2">
                <p className="text-2xl font-medium truncate">
                    {user.name}
                </p>
                <p className="text-sm truncate mt-3 text-white opacity-65">
                    {user.phone}
                </p>
                <p className="text-sm truncate text-white opacity-65">
                    {user.email}
                </p>
            </div>
            {props.showLogout ?<div className="h-full mt-2 text-lg"><button className="text-danger" onClick={(e) => {
                navigate('/auth/logout')
            }}>Đăng xuất</button> </div> : <></>}
        </div>
        <div className="flex flex-row mt-4 mb-2 uppercase">
            <div className="border rounded-[0.6rem] bg-[#1E1E32] flex items-center justify-center p-2 w-1/2 mr-1 ">
                <label className="text-gray-11">Xếp hạng</label>
                <div className="text-white ml-[6px] flex flex-wrap">{user.ranking ? <div><span className="inline-block text-center w-full">{user.ranking.code}</span> <span className="inline-block text-center w-full">{user.ranking.class}</span></div> : '--'}</div>
            </div>
            <div className="border rounded-[0.6rem] bg-[#1E1E32] flex items-center justify-center p-2 w-1/2 ml-1 flex-wrap">
                <label className="text-gray-11">Điểm</label><span
                className="text-white ml-[6px]">{user.score?.toString()}</span>
                {props.showLogout ? <a onClick={() => navigate('/profile/rank-history')}
                                       className="text-[#0BD8B6] font-exo font-[14px] inline-block w-full text-center transform-none mt-2">Lịch
                    sử điểm</a> : <></>}

            </div>
        </div>
    </div>
}

export default UserCard