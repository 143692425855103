import {QrScanner} from "@yudiel/react-qr-scanner";
import {useEffect, useState} from "react";
import {redirect, useNavigate, useParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {bottomBarState} from "../../states/common";
import Navbar from "../../components/navbar";
import * as SBService from "../../helpers/sportbookService";
import moment, {ISO_8601} from "moment";
const ScanQR = () => {
    const params = useParams();
    const [qrLink, setQrLink] = useState('')
    const navigate = useNavigate();
    const [active, setActive] = useRecoilState(bottomBarState)
    const [title, setTitle] = useState('')
    const [league, setLeague] = useState({
        id: null,
        name: null
    })

    useEffect(() => {
        setActive('qr')
        let {mode} = params;
        if (mode === 'calibration') {
            setTitle('MINIGAME')
        } else if (mode === 'ranking') {
            setTitle('Đánh giá trình độ')
        } else {
            loadLeagueDataById()
        }
    }, [])

    useEffect( () => {
        let {mode} = params;
        if (qrLink) {
            if (mode === 'calibration') {
                navigate('/calibrate/' + qrLink)
            } else if (mode === 'ranking') {
                navigate('/ranking/' + qrLink)
            } else {
                navigate(`/league/${league.id}/competitor/${qrLink}`)
            }

        }
    }, [qrLink])

    const loadLeagueDataById = async () => {
        let {mode} = params;

        let leagueData 
        const rsp = await SBService.getLeagueById(mode)
        leagueData = rsp
        if(rsp.data)leagueData = rsp.data
        if (leagueData) {
            if (leagueData.startDate) {
                leagueData.startDateObj = moment(leagueData.startDate, ISO_8601)
                leagueData.endDateObj = moment(leagueData.endDate, ISO_8601)
            }
            setLeague(leagueData)
            setTitle(leagueData.name)
        } else {
            navigate(-1)
        }
    }

    return  <div className="">
        <Navbar title={title}/>
        <div className="h-[60vh] flex justify-center items-center">
            <QrScanner
                onDecode={(result) => setQrLink(result)}
                onError={(error) => console.log(error?.message)}
                scanDelay={500}
            />
        </div>

    </div>
}

export default ScanQR