import React from "react";
import {getAssetFullUrl} from "../helpers/utils";
import moment, { ISO_8601 } from "moment";

interface HistoryItemProp {
    match: any
    isChallenger: boolean
}


const HistoryItem: React.FC<HistoryItemProp> = ({match, isChallenger}) => {
    const myChangeScore = isChallenger ? match.challengerRankModifyScore : match.acceptorRankModifyScore;
    const playedAtObj = match.playedAt ? moment(match.playedAt, ISO_8601) : null;
    return <div className="w-full flex border-b border-gray-600 border-opacity-20 py-3">
        <table className="w-[75%]">
            <tbody>
            <tr>
                <td>
                    <img className="w-[24px] h-[24px] rounded-full"
                         src={getAssetFullUrl(match.challenger.profilePicture)}/>
                </td>
                <td className="w-[80%] font-normal font-exo text-sm pl-2">
                    {match.challenger.name}
                </td>
                <td className="text-center">{match.challengerPoint}</td>
            </tr>
            <tr>
                <td className="pt-2">
                    <img className="w-[24px] rounded-full" src={getAssetFullUrl(match.acceptor.profilePicture)}/>
                </td>
                <td className="w-[80%] font-normal font-exo text-sm  pl-2 pt-2">
                    {match.acceptor.name}
                </td>
                <td className="text-center w-[10%] pt-2">{match.acceptorPoint}</td>
            </tr>
            </tbody>
        </table>
        <div className="w-[20%] text-center font-exo ">
            {myChangeScore ? <div className={`text-xl ${myChangeScore >=0 ? 'text-[#65BA75]' : 'text-red-600'}`}>{myChangeScore}</div> : <>--</>}
            <p className="text-xs text-gray-500">{playedAtObj ? playedAtObj.format('DD/MM/YYYY') : null}</p>
            <p className="text-xs text-gray-500">{playedAtObj ? playedAtObj.format('HH:mm') : null}</p>
        </div>
    </div>
}
export default HistoryItem