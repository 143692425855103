import React, { useState } from "react";
import { getAssetFullUrl } from "../helpers/utils";
import Fancybox from "./fancybox";

interface ExpertCardProps {
    data: any
}

const ExpertCard: React.FC<ExpertCardProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen((boo) => !boo);

    return <>
        <tr>
            <td className="w-[30px] align-top">
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(data.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(data.profilePicture)} />
                    </a>
                </Fancybox>
            </td>
            <td className="pb-3 px-1 max-w-[150px]">
                <h3 className="font-exo text-base font-medium leading-6">{data.name}</h3>
                <p className="font-light mt-2 font-exo text-sm">{data.phone}</p>
                <p className="font-light font-exo text-sm break-words">{data.email}</p>
            </td>
            <td className=" text-center"><h3 className="font-exo text-xl font-medium">{(data.ranking && data.ranking.class) ? data.ranking.class : ''}</h3></td>
            <td className=" text-center"><h3 className="font-exo text-xl font-medium">{data.score}</h3></td>
        </tr>
    </>
}

export default ExpertCard