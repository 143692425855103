export const CONSTANT = {
  baseUrl:
    process.env.REACT_APP_BASE_URL ?? "https://api-uat.sportbook.vn/api/",
  adminAsset:
    process.env.REACT_APP_ADMIN_ASSET_URL ??
    "https://admin-uat.sportbook.vn/storage/admin/",
  apiVersion: "v1",
  limit: 50,
  defaultMiniGameId:
    process.env.REACT_APP_DEFAULT_MINI_GAME_ID ??
    "9a821d22-66a5-47d8-bd8d-9fef80b9cc74",
};
