
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAKn-KVhSZ6NozaAQCwZ05AWaMA61LVrrM",
    authDomain: "sportbook-401008.firebaseapp.com",
    databaseURL: "https://sportbook-401008-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "sportbook-401008",
    storageBucket: "sportbook-401008.appspot.com",
    messagingSenderId: "749436763004",
    appId: "1:749436763004:web:7aca5194b8acc3f8e142f9"
};

const firebaseApp = initializeApp(firebaseConfig);
const realtimeDB = getDatabase(firebaseApp);

// Tham chiếu tới cơ sở dữ liệu

export default realtimeDB;