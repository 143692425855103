import {useEffect, useState, useReducer, useRef} from "react";
import {Link, HistoryRouterProps, useNavigate} from "react-router-dom";

import Navbar from "../../components/navbar";
import Icon from "../../components/icon";
import * as _ from 'lodash';
import Input from "../../components/form/Input";
import {regexPhoneNumber} from "../../helpers/utils";
import * as SBService from "../../helpers/sportbookService";
import {unset} from "lodash";

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";


import Resizer from "react-image-file-resizer";
import {useRecoilState} from "recoil";
import {loaderState} from "../../states/common";
import toast from "react-hot-toast";

const RegisterPage = () => {
    const [loader, setLoader] = useRecoilState(loaderState)
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const maxStep = 3;
    const navigate = useNavigate()
    const [step, setStep] = useState(1)
    const [fileAvatar, setFileAvatar] = useState('')
    const [base64FileUrl, setBase64FileUrl] = useState<string | null>('')
    const [errorMessage, setErrorMessage] = useState('')
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        profilePicture: '',
        phone: '',
        password: '',
        email: '',
        confirm_password: '',
        avatar: '',
        otp: ''
    })

    let [formError, setFormError] = useState({
        name: '',
        username: '',
        phone: '',
        password: '',
        email: '',
        confirm_password: '',
        avatar: '',
        otp: ''
    })

    const cropperRef = useRef<ReactCropperElement>(null);


    useEffect(() => {
        document.title = "SB - Register"
    }, []);

    useEffect(() => {
    }, [formData])
    const changeInput = (label: any, event: any) => {
        _.update(formData, label, () => event.target.value)
        setFormData(formData)
        _.update(formError, label, () => '')
        forceUpdate()
    }

    const nextStep = async (e: any) => {
        e.preventDefault()
        setErrorMessage('')
        if (checkValidStep()) {
            if (step < maxStep) {
                if (step === 2) {
                    setLoader(true)
                    formData.phone = formData.username
                    formData.profilePicture = 'https://placehold.co/96x96'
                    setFormData(formData)
                    let submitFormData = formData
                    if (!submitFormData.email) {
                        unset(submitFormData, 'email')
                    }
                    if (base64FileUrl) {
                        const cropper:any = cropperRef.current?.cropper;
                        let imageData = cropper.getCroppedCanvas().toDataURL();
                        submitFormData.profilePicture = imageData.substr(imageData.toString().indexOf(',') + 1)
                    }
                    try {
                        console.log(submitFormData);
                        
                        let registerData = await SBService.register(submitFormData)
                        console.log(registerData);
                        
                        setLoader(false)
                        toast('Đăng ký thành công, vui lòng đăng nhập để truy cập hệ thống')
                        navigate('/auth/login?success=true')
                    } catch (e: any) {
                        if (e.response && e.response.data) {
                            if (e.response.data.error) {
                                setErrorMessage(e.response.data.error)
                            } else if (e.response.data.errors) {
                                let arrMsg = _.map(e.response.data.errors, (val) => val)
                                let msg = arrMsg.join('\n')
                                setErrorMessage(msg)
                            }
                            setLoader(false)
                        }
                        return
                    }

                }
                setStep(step + 1)
            } else {
                try {
                    let verifyData = {
                        phone: formData.phone,
                        otp: Number(formData.otp)
                    }
                    let rspData = await SBService.confirmOTP(verifyData)
                    if (rspData.response) {
                        console.log(rspData)
                    }
                } catch (e: any) {
                    if (e.response && e.response.data) {
                        if (e.response.data.error) {
                            setErrorMessage(e.response.data.error)
                        } else if (e.response.data.errors) {
                            let arrMsg = _.map(e.response.data.errors, (val) => val)
                            let msg = arrMsg.join('\n')
                            setErrorMessage(msg)
                        }
                    }
                    return
                }
            }
        }

    }

    const prevStep = (e: any) => {
        e.preventDefault()
        if (step > 1) {
            setStep(step - 1)
        } else {
            navigate(-1)
        }
    }

    const checkValidStep = () => {
        let isValid = true;
        const initFormError = {
            name: '',
            username: '',
            phone: '',
            password: '',
            email: '',
            confirm_password: '',
            avatar: '',
            otp: ''
        }
        if (step === 1) {
            if (!formData.name) {
                initFormError.name = 'Bắt buộc'
                isValid = false
            }

            if (!formData.password) {
                initFormError.password = 'Bắt buộc'
                isValid = false
            } else if (formData.password.length < 6) {
                initFormError.password = 'Mật khẩu không được ít hơn 6 ký tự'
                isValid = false
            }

            if (!formData.username) {
                initFormError.username = 'Bắt buộc'
                isValid = false
            } else {
                if (!regexPhoneNumber(formData.username)) {
                    initFormError.username = 'Định dạng số điện thoại không đúng'
                    isValid = false
                }
            }

            if (!formData.confirm_password) {
                initFormError.confirm_password = 'Bắt buộc'
                isValid = false
            } else if (formData.confirm_password !== formData.password) {
                initFormError.confirm_password = 'Không khớp với Mật khẩu đã nhập'
                isValid = false
            }
        }
        setFormError(initFormError)

        return isValid;
    }

    const getBase64FromFile = async (file: any) => {
        return new Promise(resolve => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    };

    const handleFileInputChange = async (e: any) => {
        if (e.target.files[0]) {
            setFileAvatar(e.target.files[0]);
            let result:any = await getBase64FromFile(e.target.files[0])
            setBase64FileUrl(result)
        }
    };



    useEffect(() => {

    }, [fileAvatar])


    return <div className="register-page pb-8">
        <div className="main-auth relative flex  flex-col pt-8 z-50 px-4">
            <Navbar eventHandler={(event: any) => prevStep(event)}/>
            <form className="w-full flex flex-wrap">
                {step === 1 ?
                    <>
                        <h1 className="auth-title text-left uppercase my-6">Đăng ký</h1>
                        <Input key={'register-name'} label={"Tên"} onChange={changeInput} require={true} name={'name'}
                               error={!!formError.name} errMessage={formError.name}/>
                        <Input key={'register-email'} label={"Email"} onChange={changeInput} require={false}
                               name={'email'} error={!!formError.email} errMessage={formError.email}/>
                        <Input key={'register-username'} label={"Số điện thoại"} onChange={changeInput} require={true}
                               name={'username'} error={!!formError.username} errMessage={formError.username}/>
                        <Input type={'password'} key={'register-password'} label={"Mật khẩu"} onChange={changeInput}
                               require={true} name={'password'} error={!!formError.password}
                               errMessage={formError.password}/>
                        <Input type={'password'} key={'register-confirm-password'} label={"Xác nhận mật khẩu"}
                               onChange={changeInput} require={true} name={'confirm_password'}
                               error={!!formError.confirm_password} errMessage={formError.confirm_password}/>
                    </>

                    : <></>}

                {step === 2 ? <>
                    <h1 className="auth-title text-left uppercase my-6">Cập nhật ảnh đại diện</h1>

                    {base64FileUrl ?    <div className="block w-full flex justify-center items-center mt-2 flex-wrap flex-col"><Cropper
                        src={base64FileUrl.toString()}
                        style={{ height: 150, width: 150 }}
                        // Cropper.js options
                        initialAspectRatio={1}
                        guides={true}
                        ref={cropperRef}
                        cropBoxResizable={false}
                        scalable={false}
                        cropBoxMovable={false}
                        dragMode={'move'}
                    />  </div>: ''}

                    <label className="block w-full flex justify-center items-center mb-8 mt-2 flex-wrap flex-col">
                        {base64FileUrl ?
                            <div>

                            </div>

                            : <div
                                className="rounded-full border border-[#313143] py-11 px-12 text-2xl bg-[#12121C] text-[#5A6169]">
                                <Icon icon={'camera'}/>
                            </div>}
                        <p className="main-color text-sm mt-1 opacity-[0.65] mt-6">+ Thêm ảnh đại diện</p>
                        <input className="hidden" type="file" name="avatar"
                               onChange={(event) => handleFileInputChange(event)}/>
                    </label>
                </> : <></>}
                {step === 3 ? <>
                    <h1 className="auth-title text-left uppercase mt-6">Xác nhận Zalo OTP</h1>
                    <p className="text-sm font-light mt-1 opacity-[0.65]">Vui lòng điền mã OTP được gửi tới Zalo của
                        bạn</p>
                    <Input type={'number'} key={'register-otp'} label={"OTP"} onChange={changeInput} require={false}
                           name={'otp'} error={!!formError.otp} errMessage={formError.otp}/>
                    <div className="w-full flex justify-end left-0 bottom-4 z-10">
                        <Link className="mt-0 mb-4 main-color text-sm" to="/auth/register">Gửi lại OTP sau 30s</Link>
                    </div>

                </> : <></>}
                {errorMessage ? <div>{errorMessage}</div> : <></>}
                <button className="block w-full h-12 btn-primary text-center uppercase text-sm"
                        onClick={(e) => nextStep(e)}>{step === maxStep ? 'Xác nhận' : 'Tiếp tục'}</button>
            </form>
        </div>
        <div className="w-full flex justify-center absolute left-0 bottom-4 z-10">
            <Link className="mt-0 mb-4 main-color text-sm" to="/auth/login">Trở về đăng nhập</Link>
        </div>
    </div>;
};

export default RegisterPage;