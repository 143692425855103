import {QrScanner} from "@yudiel/react-qr-scanner";
import React, {useEffect, useState} from "react";
import {redirect, useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {bottomBarState, loaderState, userState} from "../../states/common";
import Navbar from "../../components/navbar";
import minigameLogo from 'statics/images/minigame-logo.jpeg'
import sportBookLogo from 'statics/images/logo.png'
import moment, {ISO_8601} from "moment";
import * as SBService from "../../helpers/sportbookService";
import {CONSTANT} from "../../shared/constant";
import LeagueListingItem from "../../components/league/leagueListingItem";
import toast from "react-hot-toast";

const ScanQRJoinedLeagueListing = (props:any) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useRecoilState(loaderState)
    const [active, setActive] = useRecoilState(bottomBarState)
    const [user, ] = useRecoilState(userState)
    const [leagues, setLeagues] = useState([])
    const [maxPage, setMaxPage] = useState(1);
    const [filter, setFilter] = useState({
        page: 1,
        limit: CONSTANT.limit
    })

    useEffect(() => {
        setActive('qr')
        loadAllLeage()
    }, [])


    const loadAllLeage = async () => {
        if (filter.page === 1 || filter.page < maxPage) {
            setLoader(true)
            try {
                let data = await SBService.getLeagues(filter)
                if (data) {
                    if (data.hasNextPage) {
                        setMaxPage(maxPage + 1)
                        setFilter({...filter, page: data.page})
                    } else {
                        setMaxPage(1)
                    }
                    setLeagues(leagues.concat(data.data))
                }
            } catch (e:any) {
                if (e.response && e.response.data && e.response.data.error) {
                    toast('Có lỗi xảy ra: ' + e.response.data.error)
                } else {
                    toast('Có lỗi xảy ra: ' + e.message)
                }
            }
            setLoader(false)
        }
    }

    return  <div className="">
        <Navbar title={"Các hoạt động đang diễn ra"}/>

        {leagues.length > 0 ?
            leagues.map((league, index) => {
                return <LeagueListingItem key={`league-listing-item-${index}`} isShowScanBtn={true} isJoined={true} league={league}/>
            })
            : <></>}
    </div>
}

export default ScanQRJoinedLeagueListing