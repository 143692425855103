import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Icon from "./icon";
import {useNavigate} from "react-router-dom";

const Navbar = (props: any) => {
    const navigate =  useNavigate();
    const backButtonEventHandler = (e: any) => {
        if (props.eventHandler) {
            props.eventHandler(e)
        } else {
            navigate(-1)
        }
        return
    }

    return <div className="navbar mb-2 relative py-2">
        <button className="text-xl relative z-50" onClick={(e) => backButtonEventHandler(e)}>
            <div>
                <Icon icon="arrow-left" />
            </div>
        </button>
        {props.title ? <h3 className="font-exo text-center text-sm color-[#B0B4BA] uppercase absolute w-full top-4 left-0 z-10">{props.title}</h3> : <></>}
    </div>
}

export default Navbar