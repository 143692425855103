import {QrScanner} from "@yudiel/react-qr-scanner";
import React, {useEffect, useState} from "react";
import {Outlet, redirect, useNavigate} from "react-router-dom";
import Navbar from "../../components/navbar";
import BottomBar from "../../components/bottomBar";
import Loader from "../../components/loader";
import {useRecoilState} from "recoil";
import {loaderState, userState} from "../../states/common";
import QRCode from 'react-qr-code';
import Icon from "../../components/icon";
import * as SBService from "../../helpers/sportbookService";
import * as _ from "lodash";
import {TECarousel, TECarouselItem, TEModal, TEModalBody, TEModalContent, TEModalDialog} from "tw-elements-react";
import {getAssetFullUrl} from "../../helpers/utils";
import ExpertCard from "../../components/expertCard";
import toast from "react-hot-toast";

const MyQR = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useRecoilState(loaderState)
    const [user, setUser] = useRecoilState(userState)
    const [showModal, setShowModal] = useState(false);
    const [experts, setExperts] = useState([{}])

    useEffect(() => {
        getExperts()
        const loadUser = async () => {
            try {
                const rsp = await SBService.myProfile()
                if (rsp.data) {
                    setUser(rsp.data)
                } else {
                    setUser(rsp)
                }
            } catch (e: any) {
                navigate('/auth/login')
            }
        }
        if (_.isEmpty(user.id)) {
            loadUser()
        }
    }, [])

    const getExperts = async () => {
        try {
            const expertLists = await SBService.getExperts(3)
            setExperts(expertLists.data)
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    default:
                        toast.error('Lỗi, ' + e.response.data.error)
                        break;
                }
            } else {
                toast.error('Không thể tải danh sách Expert')
            }
        }

    }

    const handleNavigationScan = () => {
        if (user && user.isCalibrated) {
            navigate('/scan-qr-selection/leagues')
        } else {
            setShowModal(true)
        }
    }


    return <div className="">
        <Navbar title="QR của tôi"/>
        <div className="w-full flex flex-wrap justify-center items-center left-0  p-4">
            <div className="w-full my-qr-code">
                <div className="bg-white py-6 px-1">
                    <QRCode size={260} value={user.id}/>
                </div>
            </div>
            <h1 className="text-white text-2xl uppercase text-center  w-full mt-2">{user.name}</h1>
            <h3 className="font-light mt-2 font-exo text-sm w-full text-center">{user.phone}</h3>
            <h3 className="font-light font-exo text-sm w-full text-center">{user.email}</h3>
            <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4 hidden">
                <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">Sẵn sàng thi đấu</p>
                <p className="font-exo text-[#FFCA16] text-[14px]">Vui lòng quay lại màn hình chính để xác nhận trận đấu
                    sau khi đối thủ quét mã QR xong.</p>
            </div>
            <div className={`w-full mt-6 flex ${user.isExpert ? '' : 'justify-center'}`}>
                <button onClick={(event) => handleNavigationScan()}
                    className={`font-exo text-[#12A594] border-[#12A594]  py-4 px-4 border rounded text-3xl flex justify-center items-center ${user.isExpert ? 'w-1/2' : 'w-full'}`}>
                    <Icon icon="qrcode"/>
                    <span className="text-sm ml-3" >QUÉT QR</span>
                </button>
                {user.isExpert ? <button
                    className="font-exo ml-3 bg-[#1E1E32] text-[#B0B4BA] border-none  py-4 px-4  rounded text-3xl w-[50%] flex justify-center items-center"
                    onClick={(event) => {
                        navigate('/scan-qr-selection')
                    }}><Icon icon="camera"/>
                    <span className="text-sm ml-3">Hoạt động</span>
                </button> : <></>}


            </div>
        </div>

        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
                        <div className="w-full text-left border border-dotted rounded-[0.5rem] py-2 px-4">
                            <p className="font-exo text-sm font-medium uppercase text-[#FFE7B3]">TÀI KHOẢN CHƯA KHẢO
                                HẠCH!</p>
                            <p className="font-exo text-[#FFCA16] text-[14px]">Điểm và xếp hạng của bạn chưa được thẩm
                                định. Vui lòng liên hệ các chuyên gia bên dưới.</p>
                        </div>
                        <div>
                            <div className="w-full bg-[#1E1E32] p-3 mt-6 text-left">
                                <table className="table-auto w-full border-spacing-2">
                                    <thead className="text-xs uppercase font-medium text-left">
                                    <tr className="h-[40px] align-top color-[#B0B4BA]">
                                        <th className="font-normal" colSpan={2}>Chuyên gia</th>
                                        <th className="font-normal text-center">Xếp hạng</th>
                                        <th className="font-normal  text-center">Điểm</th>
                                    </tr>
                                    </thead>


                                    {experts.length && !_.isEmpty(experts[0]) ?
                                        <tbody className="mt-4">
                                        {experts.map((expert, index) => {
                                            return <ExpertCard key={`expert-list-${index}`} data={expert}/>
                                        })
                                        }
                                        </tbody>
                                        : <></>}
                                </table>
                            </div>
                        </div>
                        <button onClick={() => setShowModal(false)}
                                className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                        >Đã hiểu
                        </button>
                    </TEModalBody>

                </TEModalContent>
            </TEModalDialog>
        </TEModal>

        {loader ? <Loader/> : <div/>}
    </div>
}

export default MyQR