import { child, push, ref, update } from "firebase/database";
import moment, { ISO_8601 } from "moment";
import "moment/locale/vi";
import { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Navbar from "../../components/navbar";
import realtimeDB from "../../helpers/firebase";
import * as SBService from "../../helpers/sportbookService";
import { getLeagueById } from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import { loaderState, userState } from "../../states/common";

const LeagueMatchingConfirmResultPage = () => {
  const [user, setUser] = useRecoilState(userState);
  const [player, setPlayer] = useState<any>();
  const [league, setLeague] = useState({
    id: "",
    name: "",
    type: ""
  });
  const [, setLoader] = useRecoilState(loaderState);
  const navigate = useNavigate();

  const { leagueId, matchId } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [competitor, setCompetitor] = useState<any>([]);
  const [currentMatch, setCurrentMatch] = useState<any>({});

  const [challengerScore, setChallengerScore] = useState<any>("");
  const [playerScore, setPlayerScore] = useState<any>("");

  const [challengerRankScore, setChallengerRankScore] = useState<any>({});
  const [acceptorRankScore, setAcceptorRankScore] = useState<any>({});

  const [winner, setWinner] = useState("");
  const [result, setResult] = useState<any>({});

  useEffect(() => {
    document.title = "Thông tin trận đấu";
    setLoader(true);
    const loadData = async function () {
      const rspLeague = await getLeagueById(leagueId);
      if (rspLeague) {
        setLeague(rspLeague);
      }

      if (matchId) {
        await getMatchDetail(matchId);
        await loadCompetitionData();
      }
      forceUpdate();
    };

    loadData();
  }, []);

  const getMatchDetail = async (matchFindId: string) => {
    try {
      if (matchFindId) {
        let userInfo: any;
        if (!user.id) {
          const userInfoTxt = localStorage.getItem("userInfo");
          if (userInfoTxt) {
            userInfo = JSON.parse(userInfoTxt);
          }
        } else {
          userInfo = user;
        }
        const rsp = await SBService.getMatchById(matchFindId);

        if (rsp.data) {
          if (rsp.data.league) {
            if (rsp.data.league.startDate) {
              rsp.data.league.startDateObj = moment(
                rsp.data.league.startDate,
                ISO_8601
              );
              rsp.data.league.endDateObj = moment(
                rsp.data.league.endDate,
                ISO_8601
              );
            }
            setLeague(rsp.data.league);
          }
          if (rsp.data.scores) {
            setChallengerRankScore(rsp.data.scores.challenger);
            setAcceptorRankScore(rsp.data.scores.acceptor);
          }
          if (rsp.data.challenger.id == user.id) {
            setPlayer(rsp.data.challenger);
            setCompetitor(rsp.data.acceptor);
          } else {
            setPlayer(rsp.data.acceptor);
            setCompetitor(rsp.data.challenger);
          }
          setCurrentMatch(rsp.data);
          const resultRsp = await SBService.getMatchResultByMatchId(
            matchFindId
          );
          if (resultRsp && resultRsp.data) {
            setResult(resultRsp.data[0]);
            setChallengerScore(resultRsp.data[0].challengerPoint);
            setPlayerScore(resultRsp.data[0].acceptorPoint);
            if (
              resultRsp.data[0].acceptorPoint >
              resultRsp.data[0].challengerPoint
            ) {
              setWinner("acceptor");
            } else if (
              resultRsp.data[0].acceptorPoint <
              resultRsp.data[0].challengerPoint
            ) {
              setWinner("challenger");
            }
          }
        }
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          case "playersNotFoundInLeague":
            toast.error("Lỗi, Không tìm thấy đối thủ trong giải đấu");
            break;
          default:
            toast.error("Lỗi, " + e.response.data.error);
        }
      } else {
        toast.error("Không tìm thấy đối thủ trong giải đấu");
      }
    }
    setLoader(false);
  };

  const sendNotificationToUser = (id = "") => {
    const listenPath = `notifications/${id ? id : competitor.id}`;
    const messagesRef = ref(realtimeDB, listenPath);
    const newPostKey = push(child(ref(realtimeDB), "posts")).key;
    let newData: any = {};
    newData = {
      content: "thách đấu",
      challengerId: player.player.id,
      leagueId: leagueId,
      isRead: false,
      matchId: currentMatch.id,
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
    };
    const updates = { ["/" + newPostKey]: newData };

    // Ghi đè dữ liệu trong nút 'messages' bằng dữ liệu mới
    update(messagesRef, newData)
      .then(() => {
        console.log("Data added successfully!");
      })
      .catch((error) => {
        console.error("Error adding data: ", error);
      });
  };

  const loadCompetitionData = async () => {
    try {
      let leagueData;
      const rsp = await SBService.getLeagueById(leagueId);
      leagueData = rsp;
      if (rsp.data) leagueData = rsp.data;
      if (leagueData) {
        if (leagueData.startDate) {
          leagueData.startDateObj = moment(leagueData.startDate, ISO_8601);
          leagueData.endDateObj = moment(leagueData.endDate, ISO_8601);
        }
        setLeague(leagueData);
      }
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể tìm giải đấu");
      }
      navigate(-1);
    }
    setLoader(false);
  };

  const handleConfirmResult = async () => {
    try {
      await SBService.confirmResult(
        currentMatch.id,
        result.id,
        currentMatch.challenger.id,
        currentMatch.acceptor.id
      );
      toast.success("Xác nhận kết quả thành công");
      return navigate(`/profile`);
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể xác nhận kết quả trận đấu");
      }
    }
    forceUpdate();
  };

  const handleDenyResult = async () => {
    try {
      await SBService.refuseResult(currentMatch.id);
      toast.success("Huỷ kết quả thành công");
      return navigate(`/league/${leagueId}/match/${currentMatch.id}`);
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể từ chối kết quả trận thách đấu");
      }
    }
    forceUpdate();
  };

  const challengerCompetitor = async () => {
    try {
      if (currentMatch && currentMatch.id && currentMatch.status === "DRAFT")
        await SBService.makeChallenger(currentMatch.id);
      sendNotificationToUser();
      navigate("/");
    } catch (e: any) {
      if (e.response && e.response.data && e.response.data.error) {
        switch (e.response.data.error) {
          default:
            toast.error("Lỗi, " + e.response.data.error);
            break;
        }
      } else {
        toast.error("Không thể thách đấu đối thủ");
      }
    }
    forceUpdate();
  };

  const handleSubmitMatch = () => {
    return navigate(`/league/${leagueId}/match/${currentMatch.id}`);
  };

  return (
    <div className="py-6">
      <Navbar title={"THÔNG TIN TRẬN ĐẤU"} />
      <div className="flex place-content-between">
        <h1 className="heading-8 text-2xl font-medium uppercase text-[--dark-neutral-gray-12] mb-4">
          {league.name}
        </h1>
      </div>
      <div className="w-full bg-[#1E1E32] border-none rounded-[0.6rem] py-4">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <img
              className="w-[96px] h-[96px] object-cover object-center rounded-full"
              src={getAssetFullUrl(competitor?.profilePicture)}
              alt=""
            />
          </div>
          <div className="min-w-0 mt-4">
            <p className="text-lg font-medium truncate">{competitor?.name}</p>
            <div className="truncate mt-3 text-white opacity-65 flex w-full">
              <div className="flex items-center justify-center">
                <label className="text-gray-11 uppercase">Xếp hạng</label>
                <div className="text-white ml-[6px] flex flex-wrap">
                  {competitor ? (
                    <div>
                      <span className="inline-block text-center w-full">
                        {competitor?.ranking?.class}
                      </span>
                    </div>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
              <div className=" flex items-center justify-center ml-5">
                <label className="text-gray-11 uppercase">Điểm</label>
                <span className="text-white ml-[6px]">{competitor?.score}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentMatch && currentMatch.challenger && currentMatch.acceptor ? (
        <div className="mt-4 rounded w-full px-4 py-2 bg-[#0D1514] border border-[#084843] mb-4">
          <h3 className="text-[#0BD8B6] uppercase font-medium text-sm font-exo">
            Thể thức thi đấu
          </h3>
          <p className="text-[#FFCA16] font-exo text-sm font-medium">
            Chạm {currentMatch?.race} để thắng, { league.type !== 'ELIMINATION' ? <>({currentMatch?.race - 1}-
            {currentMatch?.race - 1} hòa) </> : <></> }
          </p>
          {currentMatch.handicappingPoint > 0 ? (
            <p className="text-[#FFCA16] font-exo text-sm font-medium">
              <span className="text-[#0BD8B6]">
                {currentMatch.challenger.name}
              </span>{" "}
              chấp{" "}
              <span className="text-[#0BD8B6]">
                {currentMatch.acceptor.name}
              </span>{" "}
              {Math.abs(currentMatch.handicappingPoint)} ván
            </p>
          ) : (
            <p className="text-[#FFCA16] font-exo text-sm font-medium">
              <span className="text-[#0BD8B6]">
                {currentMatch.acceptor.name}
              </span>{" "}
              chấp{" "}
              <span className="text-[#0BD8B6]">
                {currentMatch?.challenger?.name}
              </span>{" "}
              {Math.abs(currentMatch.handicappingPoint)} ván
            </p>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="bg-[#2D2D46] rounded py-2">
        <h2 className="font-exo text-lg font-medium text-gray-11 mb-4 p-3 text-center uppercase">
          Kết quả trận đấu
        </h2>

        {currentMatch && currentMatch.challenger ? (
          <div className="mt-4 py-3 px-4 flex rounded-[1rem]">
            <div className="w-[calc(100%-80px)] pr-4 font-exo text-gray-11">
              <div className="flex justify-start items-center">
                <img
                  className="w-[32px] h-[32px] rounded-full"
                  src={getAssetFullUrl(
                    currentMatch?.challenger?.profilePicture
                  )}
                />
                <span className="text-white ml-2 uppercase">
                  {currentMatch?.challenger?.name}
                </span>
              </div>
              <div className="flex flex-wrap text-sm mt-2">
                <div className="w-[50%] pr-4 hidden">
                  <p>
                    XH mùa giải:{" "}
                    {currentMatch.challenger
                      ? currentMatch.challenger.playerRankInLeague
                      : ""}
                  </p>
                </div>
                <div className="w-[50%] hidden">
                  <p>
                    Điểm:{" "}
                    {currentMatch?.challengerLeaguePlayer?.ranking || "--"}
                  </p>
                </div>
                <div className="w-[50%] pr-4">
                  <p>
                    Trình độ:{" "}
                    {currentMatch.challenger && currentMatch.challenger.ranking
                      ? currentMatch.challenger.ranking.class
                      : "--"}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p>
                    Điểm: {currentMatch.challenger.score}{" "}
                    {winner === "acceptor" ? (
                      <span>
                        (
                        <b className="text-[#EB9091]">
                          -{challengerRankScore.loss}
                        </b>
                        )
                      </span>
                    ) : winner === "challenger" ? (
                      <span>
                        (
                        <b className="text-[#46A758]">
                          +{challengerRankScore.win}
                        </b>
                        )
                      </span>
                    ) : (
                      <span>
                        (
                        <b className="text-[#46A758]">
                          +{challengerRankScore.draw}
                        </b>
                        )
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[80px]">
              <input
                type="number"
                className={
                  winner === "acceptor"
                    ? "bg-[#201314] border border-[#500F1C] rounded-[1rem] text-center w-[83px] h-[72px] text-[40px] py-3 px-2"
                    : winner === "challenger"
                    ? "bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center w-[83px] h-[72px] bg-main text-[40px] py-3 px-2"
                    : "bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center w-[83px] h-[72px] bg-main text-[40px] py-3 px-2"
                }
                value={challengerScore}
                readOnly={true}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {currentMatch && currentMatch.acceptor ? (
          <div className="mt-4 py-3 px-4 flex rounded-[1rem]">
            <div className="w-[calc(100%-80px)] pr-4 font-exo text-gray-11">
              <div className="flex justify-start items-center">
                <img
                  className="w-[32px] h-[32px] rounded-full"
                  src={getAssetFullUrl(currentMatch.acceptor.profilePicture)}
                />
                <span className="text-white ml-2 uppercase">
                  {currentMatch.acceptor.name}
                </span>
              </div>
              <div className="flex flex-wrap text-sm mt-2">
                <div className="w-[50%] pr-4 hidden">
                  <p>
                    XH mùa giải:{" "}
                    {currentMatch.acceptor
                      ? currentMatch.acceptor.playerRankInLeague
                      : ""}
                  </p>
                </div>
                <div className="w-[50%] hidden">
                  <p>
                    Điểm: {currentMatch?.acceptorLeaguePlayer?.ranking || "--"}
                  </p>
                </div>
                <div className="w-[50%] pr-4">
                  <p>
                    Trình độ:{" "}
                    {currentMatch.acceptor && currentMatch.acceptor.ranking
                      ? currentMatch.acceptor.ranking.class
                      : "--"}
                  </p>
                </div>
                <div className="w-[50%]">
                  <p>
                    Điểm: {currentMatch.acceptor.score}{" "}
                    {winner === "challenger" ? (
                      <span>
                        (
                        <b className="text-[#EB9091]">
                          -{acceptorRankScore.loss}
                        </b>
                        )
                      </span>
                    ) : winner === "acceptor" ? (
                      <span>
                        (
                        <b className="text-[#46A758]">
                          +{acceptorRankScore.win}
                        </b>
                        )
                      </span>
                    ) : (
                      <span>
                        (
                        <b className="text-[#46A758]">
                          +{acceptorRankScore.draw}
                        </b>
                        )
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[80px]">
              <input
                type="number"
                className={
                  winner === "challenger"
                    ? "bg-[#201314] border border-[#500F1C] rounded-[1rem] text-center w-[83px] h-[72px] text-[40px] py-3 px-2"
                    : winner === "acceptor"
                    ? "bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center w-[83px] h-[72px] bg-main text-[40px] py-3 px-2"
                    : "bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center w-[83px] h-[72px] bg-main text-[40px] py-3 px-2"
                }
                value={playerScore}
                readOnly={true}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11 relative">
        <div className="flex justify-between w-full">
          <span className="font-exo text-gray-11"> Bill thi đấu</span>
        </div>
        <div>
          {currentMatch.billPhoto ? (
            <img className="block" src={currentMatch.billPhoto} />
          ) : (
            ""
          )}
        </div>
      </div>

      {result && result.submitter && result.submitter.id !== user.id ? (
        <div className="flex flex-1 mt-2">
          <div className="w-1/2 pr-1">
            <button
              className="block w-full p-4 bg-[#0EB39E] text-center uppercase rounded border-2 border-[#ADF0DD] button-custom-shadow text-xl"
              onClick={() => handleConfirmResult()}
            >
              Chấp nhận
            </button>
          </div>
          <div className="w-1/2 pl-1">
            <button
              className="block w-full p-4 bg-[#1E1E32] text-center uppercase rounded border-2 border-[#3B3B47] text-gray-11 text-xl"
              onClick={() => handleDenyResult()}
            >
              Từ chối
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LeagueMatchingConfirmResultPage;
