import {usePlayerList} from "hooks/user";
import {UserInterface} from "interfaces/user";
import {getAssetFullUrl} from "../../../../helpers/utils";
import {router} from "../../../../router";
import {useNavigate} from "react-router-dom";

const UserRow = ({user}: { user: UserInterface }) => {
    const totalMatch = user?.drew + user?.lose + user?.won;
    return (
        <div className="grid grid-cols-9 py-2 border-t-[0.5px] border-[#5A6169] text-center">
            <div className="col-span-1">
                {[1, 2, 3].indexOf(user?.leaguePosition) !== -1 ? (
                    <img
                        src={`/rank_${user?.leaguePosition}.svg`}
                        alt={user?.leaguePosition?.toString()}
                    />
                ) : (
                    user?.leaguePosition
                )}
            </div>
            <div className="col-span-1">{user?.ranking}</div>
            <div className="col-span-4 text-start flex gap-2">
                <div className="w-[28px] h-[28px] rounded-full overflow-hidden">
                    <img
                        src={getAssetFullUrl(user?.player?.profilePicture)}
                        alt="user_pic"
                        width={28}
                        height={28}
                    />
                </div>
                <div className="text-[14px] text-[#B0B4BA]">{user?.player?.name}</div>
            </div>
            <div className="col-span-1 text-center items-center">
                {user?.player?.ranking?.class}
            </div>
            <div className="col-span-2">{totalMatch}</div>
        </div>
    );
};

const OngoingLeaguePlayers = ({leagueID}: { leagueID: string }) => {
    const players = usePlayerList(leagueID);
    const navigate = useNavigate();
    return (
        <>
            <div className="mt-2 relative font-exo"><h3 className="text-[16px] text-[#EDEEF0]">Danh sách tham gia</h3>
                <span className="cursor-pointer text-[14px] text-[#0BD8B6] absolute right-0 top-1"
                      onClick={() => navigate("/league/" + leagueID + '/history')}
                >Xem lịch sử thi đấu</span></div>
            <section className="p-5 mt-2 bg-[#1E1E32]" id="user-list">
                <div className="grid grid-cols-9 py-2 items-center text-center text-[12px] text-[#D9D9D9] font-oswald">
                    <div className="col-span-1">HẠNG</div>
                    <div className="col-span-1">ĐIỂM</div>
                    <div className="col-span-4 text-start">CƠ THỦ</div>
                    <div className="col-span-1">HẠNG</div>
                    <div className="col-span-2">SỐ TRẬN</div>
                </div>
                {players?.map((item) => (
                    <UserRow user={item}/>
                ))}
            </section>
        </>

    );
};

export default OngoingLeaguePlayers;
