import {useEffect} from "react";
import {useRecoilState} from "recoil";
import {userState} from "../../states/common";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const LogoutPage = () => {
 const [user, setUser] = useRecoilState(userState)
 const navigate = useNavigate()
 useEffect(() => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  setUser({
   email: "",
   id: "",
   isCalibrated: false,
   isExpert: false,
   name: "",
   phone: "",
   profilePicture: "",
   ranking: {code: "--", class: "--"},
   score: 0,
   leagues: [],
   roles:{isExpert:false, isReferee:false}
  })
  toast('Đăng xuất tài khoản thành công')
  navigate('/auth/login')

 },[])
  return <></>
}

 export default LogoutPage