import React, { useEffect } from "react";
import { getAssetFullUrl } from "../../helpers/utils";
import Fancybox from "components/fancybox";
import RankIconItem from "./RankItem";

interface RankUserCardProps {
    player: any,
    index: number
}

const RankUserCard: React.FC<RankUserCardProps> = ({ player, index }) => {

    return <div className="h-[65px] text-center flex font-semibold ">
        <div className="flex w-full">
            <div className="w-[70%]">
                <div className="text-sm font-normal text-left opacity-70">HẠNG CỦA BẠN</div>
                <div className="w-full flex mt-3">
                    <div className="w-[20%] flex items-center">
                        <div className="w-1/2">
                            <RankIconItem rank={(index > -1 && player.ranking) ? (player.leaguePosition ?? (index + 1)) : 0}  isLeft={true}/>
                        </div>
                        <div className="w-1/2">
                            <span>{player.ranking}</span>
                        </div>
                    </div>
                    <div className="w-[50%] flex items-center pl-1">
                        <Fancybox>
                            <a data-fancybox="gallery" href={getAssetFullUrl(player?.player?.profilePicture)}>
                                <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(player?.player?.profilePicture)} />
                            </a>
                        </Fancybox>
                        <span className="text-sm font-exo">{player?.player?.name}</span>
                    </div>
                </div>

            </div>
            <div className="w-[30%] text-sm font-normal flex">
                <div className="w-1/3">
                    <div className="opacity-70">THẮNG</div>
                    <div className="font-exo mt-3">{player?.won}</div>
                </div>
                <div className="w-1/3">
                    <div className="opacity-70">H</div>
                    <div className="font-exo mt-3">{player?.drew}</div>
                </div>
                <div className="w-1/3">
                    <div className="opacity-70">THUA</div>
                    <div className="font-exo mt-3">{player?.lose}</div>
                </div>
            </div>
        </div>
    </div>
}

export default RankUserCard