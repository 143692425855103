import {useEffect, useState} from "react";
import loginBg from "statics/images/login-bg.jpeg"
import logo from 'statics/images/logo.png'
import {Link, useNavigate} from "react-router-dom";
import * as _ from "lodash";
import Input from "../../components/form/Input";
import * as SBService from "../../helpers/sportbookService";
import {useRecoilState} from "recoil";
import {loaderState} from "../../states/common";


const LoginPage = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useRecoilState(loaderState)
    const [errorMessage, setErrorMessage] = useState('')
    const [formData, setFormData] = useState({
        phone: '',
        password: '',
    })

    let [formError, setFormError] = useState({
        phone: '',
        password: '',
    })


    useEffect(() => {
        document.title = "SB - Login" 
    }, []);

    const changeInput = (label: any, event: any) => {
        _.update(formData, label, () => event.target.value)
        setFormData(formData)
        _.update(formError, label, () => '')
    }

    const submitLogin = async (e:any) => { 
        e.preventDefault()
        //console.log(1);
        
        try {
            setLoader(true)
            let dataLogin
            let rsp = await SBService.login(formData)
            dataLogin = rsp
            if(rsp.data){
                dataLogin = rsp.data
            }
            if (dataLogin.token) {                
                localStorage.setItem('token', dataLogin.token)
                localStorage.setItem('refreshToken', dataLogin.refreshToken)
                setLoader(false)
                navigate('/')
            }
        } catch (e: any) {
            if (e.response && e.response.data) {
                if ( e.response.data.error) {
                    setErrorMessage(e.response.data.error)
                } else if (e.response.data.errors) {
                    let arrMsg = _.map(e.response.data.errors, (val) =>  val)
                    let msg = arrMsg.join('\n')
                    setErrorMessage(msg)
                }
            }
            setLoader(false)
            return
        }
    }

    return <div className="login-page">
        <div className="main-auth relative flex justify-center items-center flex-col pt-8 z-50">
            <div className="logo">
                <img className="mt-2 mb-2" src={logo} alt="Logo"/>
            </div>

            <h1 className="auth-title text-center uppercase mt-6">Đăng nhập</h1>
            <form className="w-full flex justify-center items-center flex-wrap mt-6 px-4">
                <Input type={'number'} key={'login-phone'} label={"Số điện thoại"} onChange={changeInput} require={true} name={'phone'} error={!!formError.phone} errMessage={formError.phone} />
                <Input type={'password'}  key={'register-password'} label={"Mật khẩu"} onChange={changeInput} require={true} name={'password'} error={!!formError.password} errMessage={formError.password} />
                <div className="w-full flex justify-end">
                    <Link className="mt-0 mb-4 main-color font-light text-sm" to="/auth/forgot-password">Quên mật khẩu</Link>
                </div>
                {errorMessage ? <div className="bg-red-200 text-white px-2 py-1">{errorMessage}</div> : <></> }
                <button className="block w-full h-12 btn-primary text-center uppercase text-sm" onClick={(e) => submitLogin(e)}>Đăng nhập</button>
            </form>
        </div>
        <div className="w-full flex justify-center absolute left-0 bottom-4 z-10">
            <Link className="mt-0 mb-4 main-color text-sm" to="/auth/register">Tạo tài khoản mới</Link>
        </div>
        <div className="login-bottom-bg w-full absolute bottom-0 left-0 z-0">
            <img className="w-full" src={loginBg}/>
        </div>
    </div>;
};

export default LoginPage;