import {useEffect, useState} from "react";
import loginBg from "statics/images/login-bg.jpeg"
import logo from 'statics/images/logo.png'
import {Link} from "react-router-dom";


const ForgotPasswordPage = () => {
    const [formData, setFormData] = useState({
        username: ''
    })


    useEffect(() => {
        document.title = "SB - Recovery Password"
    }, []);

    const changeInput = (label: any, event: any) => {
        // @ts-ignore
        formData[label] = event.target.value
        setFormData(formData)
    }

    return <div className="">
        <div className="main-auth relative flex justify-center items-center flex-col pt-8 z-50">
            <div className="logo">
                <img className="mt-2 mb-2" src={logo} alt="Logo"/>
            </div>

            <h1 className="auth-title text-center uppercase mt-6">Khôi phục mật khẩu</h1>
            <form className="w-full flex justify-center items-center flex-wrap mt-6 px-4">
                <div
                    className={'group w-full relative mb-3 bg-main px-2 pt-5 pb-1 h-7 box-content border border-[#EDEDED] rounded-lg ' + (formData.username ? 'has-value' : '')}>
                    <input
                        type="text"
                        name="username" onChange={(e) => changeInput('username', e)}
                        className="peer text-sm px-1 block min-h-[auto] w-full  rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                        required/>
                    <label
                        htmlFor="username"
                        className="pointer-events-none  font-light top-2   absolute left-3 top-0 mb-0 max-w-[90%] origin-top-left truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:scale-[0.7] peer-focus:-translate-y-[0.6rem] peer-data-[te-input-state-active]:scale-[0.7] peer-data-[te-input-state-active]:-translate-y-[0.6rem] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                    >Số điện thoại
                    </label>
                </div>
                <div className="w-full flex justify-end">
                    <Link className="mt-0 mb-4 main-color font-light text-sm" to="/auth/login">Quay lại trang đăng nhập</Link>
                </div>
                <button className="block w-full h-12 btn-primary text-center uppercase text-sm">Yêu cầu khôi phục</button>

            </form>
        </div>
        <div className="login-bottom-bg w-full absolute bottom-0 left-0 z-0">
            <img className="w-full" src={loginBg}/>
        </div>
    </div>;
};

export default ForgotPasswordPage;