import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Loader from "../components/loader";


import { useRecoilState } from "recoil";
import {
    bottomBarState,
    loaderState,
    navbarTitleState,
    showBottomBarState,
    showNavbarState,
    userState
} from "../states/common";
import {useEffect} from "react";
import BottomBar from "../components/bottomBar";
import * as SBService from "../helpers/sportbookService";
import * as _ from "lodash";
import {getAssetFullUrl} from "../helpers/utils";
import GlobalNotification from "../components/GlobalNotification";
import GlobalErrorNotification from "../components/GlobalErrorNotification";

const Layout = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useRecoilState(loaderState)
    const [navbarTitle , setNavbarTitle] = useRecoilState(navbarTitleState)
    const [showNavbar , setShowNavbar] = useRecoilState(showNavbarState)
    const [showBottomBar , setBottomBar] = useRecoilState(showBottomBarState)
    const [user , setUser] = useRecoilState(userState)


    useEffect(() => {
        setLoader(false)
        if (!localStorage.getItem('token')) {
            navigate('/auth/login')
        }

        const loadUser = async () => {
            try {
                let dataProFile
                const rsp = await SBService.myProfile()
                dataProFile = rsp
                if(rsp.data){
                    dataProFile = rsp.data
                }
                if (dataProFile){                                        
                    if(dataProFile.profilePicture) {                        
                        if (dataProFile.profilePicture.indexOf('https') === -1) {
                            dataProFile.profilePicture = getAssetFullUrl(dataProFile.profilePicture)
                        }
                    } else {
                        dataProFile.profilePicture = 'https://placehold.co/96x96'
                    }
                    setUser(dataProFile)
                    localStorage.setItem('userInfo', dataProFile)
                    setLoader(false)
                } else {
                    navigate('/auth/login')
                    setLoader(false)
                }
            } catch (e: any) {
                navigate('/auth/login')
                setLoader(false)
            }
        }  
              
        if (user && !user.id) {
            setLoader(true)
            loadUser()
        }
    }, [])




    return (
        <div className="main mx-auto px-4 pb-[124px] relative w-screen top-0">
            {user.id ? <Outlet /> : <></>}
            <GlobalNotification/>
            <GlobalErrorNotification/>
            {showBottomBar ?  <BottomBar/> : <></>}
            {loader ? <Loader/> : <div/>}
        </div>
    )
};

export default Layout;