import { atom } from "recoil";
import { selector, useRecoilState } from 'recoil';
import * as _ from 'lodash'

export const loaderState = atom({
    key: "loader",
    default: false,
});

export const showNavbarState = atom({
    key: "showNavbar",
    default: true,
});

export const navbarTitleState = atom({
    key: "navbarTitle",
    default: "",
});

export const tokenState = atom( {
    key: "token",
    default: null,
})

export const bottomBarState = atom( {
    key: "bottomBar",
    default: 'profile',
})


export const showBottomBarState = atom( {
    key: "showBottomBar",
    default: true,
})

interface PlayerInterface {
    id: string,
    name: string,
    phone: string,
    email: string,
    ranking: {code: string, class: string},
    isCalibrated: boolean,
    isExpert: boolean,
    score: Number,
    profilePicture: string,
    leagues: Array<PlayerLeagueInterface>,
    roles:{isExpert:boolean, isReferee:boolean}
}

interface PlayerLeagueInterface {
    id: string,
    logo: string | null,
    banner: string
}

export  const userState = atom<PlayerInterface>({
    key: "user",
    default: {
        id: '',
        name: '',
        phone: '',
        email: '',
        ranking: {code: "--", class: "--"},
        isCalibrated: false,
        isExpert: false,
        score: 0,
        profilePicture: '',
        leagues:[],
        roles:{isExpert:false, isReferee:false}
    },
    dangerouslyAllowMutability: true
})

export const userLeagueSelector = selector({
    key: 'LeagueIdSelector',
    get: ({get}) => {        
        const list = get(userState)        
        if(list && list.leagues && !_.isEmpty(list.leagues) && list.leagues[0]){
            return list.leagues.map(item => item.id)
        }
        return ''
    }
});

export const showErrorNotificationState = atom<boolean>( {
    key: "errorNotification",
    default: false
})

export const errorNotificationContentState = atom<any>( {
    key: "errorNotificationContent",
    default: ""
})

export const errorNotificationTitleState = atom<any>( {
    key: "errorNotificationTitle",
    default: ""
})

export const errorNotificationButtonContentState = atom<any>( {
    key: "errorNotificationButtonContent",
    default: ""
})