
const Loader = () => {
    return <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen"><div
        className="relative z-[30] inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
    <span
        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span>
    </div>
        <div className="absolute w-full h-full bg-[#000000] opacity-50"></div>
    </div>;
}

export default Loader