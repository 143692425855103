import React, { useEffect, useState, useReducer } from "react";
import { getAssetFullUrl } from "../../helpers/utils";
import * as SBService from "../../helpers/sportbookService"
import toast from "react-hot-toast";
import { useRecoilState, useRecoilValue } from "recoil";
import { loaderState, userState } from "../../states/common";
import Fancybox from "components/fancybox";
import { useNavigate } from "react-router-dom";
import { TECollapse, TERipple } from "tw-elements-react";
import Icon from "components/icon";
import RankIconItem from "./RankItem";

interface CompetitorCardProps {
    player: any,
    leagueId: any,
    listPlayer: any,
    index: number,
    hideCountMatch: boolean
}

const CompetitorCard: React.FC<CompetitorCardProps> = ({ player, leagueId, listPlayer, index, hideCountMatch = false }) => {
    const user = useRecoilValue(userState)
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);
    const [loader, setLoader] = useRecoilState(loaderState)
    const navigate = useNavigate();
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const callPlayer = async () => {
        setLoader(true)
        try {
            let playerInfor = await SBService.getUserById(player.player.id)
            if (playerInfor && playerInfor.phone) {
                window.open(`tel:${playerInfor.phone}`)
            } else {
                toast('Có lỗi xảy ra: Không thể lấy thông tin người chơi')
            }
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                toast('Có lỗi xảy ra: Không thể lấy thông tin người chơi')
            } else {
                toast('Có lỗi xảy ra: ' + e.message)
            }
        }
        setLoader(false)
    }

    const matchingWithAnotherPlayer = () => {
        if (player.player.id != user.id && user.ranking?.class) {
            navigate(`/league/${leagueId}/competitor/${player.player.id}?viewOnly=true`)
        } else {
            if (!user.ranking?.class) {
                toast.error('Bạn chưa được khảo hạch!')
            }
        }
    }


    return <div className="w-full w-fit">
        <div className="flex flex-wrap min-h-[50px] items-center w-full">
            {hideCountMatch ? <></> :
                <div className="w-[20%] flex items-center text-center">
                    <div className="w-1/2 text-left">
                    <RankIconItem rank={player.ranking ? (index + 1) : 0} isLeft={true}/>
                </div>
                <div className="w-1/2 text-left pl-3">
                    <span>{player.ranking}</span>
                </div>
            </div>
            }
            <div className={hideCountMatch ? 'w-[70%] flex items-center' : 'w-[50%] flex items-center'}>
                <Fancybox>
                    <a data-fancybox="gallery" href={getAssetFullUrl(player?.player?.profilePicture)}>
                        <img className="w-[24px] h-[24px] object-cover object-center rounded-full mr-2" src={getAssetFullUrl(player?.player?.profilePicture)} />
                    </a>
                </Fancybox>
                <a className="w-full" onClick={() => matchingWithAnotherPlayer()}><span className="font-normal font-exo text-sm pl-2">{player?.player?.name}</span></a>
            </div>

            <span className="w-[10%] font-normal font-exo text-sm pl-2">{ player.player?.ranking?.class ?? '--'}</span>
            <span className="w-[10%] font-normal font-exo text-sm pl-2">{ player.playedWith}/2</span>
            {hideCountMatch ? <></> :
                <TERipple className="w-[10%]">
                <a className="inline-block rounded dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white bg-[#1E1E32] p-2 text-xs font-medium"
                    onClick={toggleShow}>
                    {!show ? <Icon icon={'plus'} /> : <Icon icon={'minus'}/>}
                </a>
            </TERipple>
            }
            <TECollapse className="w-full h-full" show={show}>
                <div className="block bg-transparent">
                    <div className="text-sm font-normal font-exo flex my-3 ml-4 mr-4 mb-4 text-center justify-between gap-8 w-full ">
                            <div className="w-1/4 text-left">
                                <div className="">
                                    <div className="opacity-70 text-sm">Số điểm</div>
                                    <div className="mt-1 font-semibold text-lg">
                                        {player?.won * 3 + player?.drew + player?.lose * 0}
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/4">
                                <div className="text-left">
                                    <div className="opacity-70 text-sm">Hiệu số</div>
                                    <div className="mt-1 font-semibold text-lg">
                                        {player?.subtractionScore > 0 ? '+' : ''}{player?.subtractionScore}
                                    </div>
                                </div>
                            </div>
                        <div className="w-1/2 pr-6">
                            <button
                                className="font-exo text-[#12A594] border-[#12A594] p-2 border rounded text-3xl flex justify-center items-center float-right">
                                <Icon icon="phone" size={'2xs'}/>
                                <span className="text-sm ml-3">LIÊN HỆ</span>
                            </button>
                        </div>
                    </div>
                    {player?.won + player?.drew + player?.lose > 0 &&
                        <div className="text-sm font-normal font-exo flex my-3 ml-4 mr-4 mb-4 text-center gap-8 w-full">
                            <div className="w-1/4">
                                <div className="text-left">
                                    <div className="opacity-70 text-sm">Số trận</div>
                                    <div
                                        className="mt-1 font-semibold text-lg">{player?.won + player?.drew + player?.lose}</div>
                                </div>
                            </div>
                            <div className="w-1/4">
                                <div className="max-w-fit text-left">
                                    <div className="opacity-70 text-sm">Thắng/Hoà/Thua</div>
                                    <div className="mt-1 font-semibold text-lg">
                                        <span className="text-[#65BA75]">{player?.won}</span>/
                                        <span className="text-[#989898]">{player?.drew}</span>/
                                        <span className="text-[#EB9091]">{player?.lose}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/2"></div>

                        </div>}
                </div>
            </TECollapse>
        </div>

    </div>
}

export default CompetitorCard