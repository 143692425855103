import React, {useEffect, useState} from "react";
import {Slider} from "@mui/material";


interface PlayerLeagueProgressProps {
    league: any,
    playerLeagueWeek: any,
    startOfWeek: any,
    endOfWeek: any,
    state: string
}



const PlayerLeagueProgress: React.FC<PlayerLeagueProgressProps> = ({
                                                                       league,
                                                                       playerLeagueWeek,
                                                                       startOfWeek,
                                                                       endOfWeek,
                                                                       state
                                                                   }) => {
    const [baseClass, setBaseClass] = useState(state)
    const [minRequired, setMinRequired] = useState(0)
    const [progressValue, setProgressValue] = useState(0)
    const [labelText, setLabelText] = useState('chưa diễn ra');
    const [marks, setMarks] = useState([
        {
            value: 0,
            label: '0',
        },
        {
            value: 100,
            label: '100',
        },
    ]);
    useEffect(() => {
        const minRequiredCalculated = playerLeagueWeek ? playerLeagueWeek.minWeekRequired : league.minMatchesPerWeek;
        setMarks([
            {
                value: 0,
                label: '0',
            },
            {
                value: minRequiredCalculated,
                label: minRequiredCalculated.toString(),
            },
        ])
        setMinRequired(minRequiredCalculated)
        if (state === 'pass') {
            if (!playerLeagueWeek) {
                setBaseClass('not-claimed')
                setProgressValue(0)
                setLabelText('Không hoàn thành')
            } else {
                if (playerLeagueWeek.numberPlayedMatchInWeek < minRequiredCalculated) {
                    setBaseClass('not-claimed')
                    setProgressValue(playerLeagueWeek.numberPlayedMatchInWeek)
                    setLabelText('Không hoàn thành')
                } else {
                    setBaseClass('claimed')
                    setProgressValue(minRequiredCalculated)
                    setLabelText('Hoàn thành')
                }
            }
        } else if (state === 'running') {
            if (!playerLeagueWeek) {
                setBaseClass('running-not-claimed')
                setProgressValue(0)
                setLabelText('Chưa hoàn thành')
            } else {
                if (playerLeagueWeek.numberPlayedMatchInWeek < minRequiredCalculated) {
                    setBaseClass('running-not-claimed')
                    setProgressValue(playerLeagueWeek.numberPlayedMatchInWeek)
                    setLabelText('Chưa hoàn thành')
                } else {
                    setBaseClass('claimed')
                    setProgressValue(minRequiredCalculated)
                    setLabelText('Hoàn thành')
                }
            }
        } else {
            setProgressValue(0)
            setLabelText('Chưa diễn ra')
        }

    }, []);


    function valuetext(value: number) {
        return `${value}`;
    }



    return <div className={'px-12 w-full slider-progress ' + baseClass}>
        <div className="card w-full rounded-lg flex flex-wrap p-3 relative">
            <div className="text-[#5A6169] font-bold text-left w-full">
                {startOfWeek} - {endOfWeek}
            </div>
            <div className="custom-marker w-full pr-3">
                <Slider
                    aria-label="Always visible"
                    valueLabelDisplay="on"
                    value={progressValue}
                    getAriaValueText={valuetext}
                    step={null}
                    min={0}
                    max={playerLeagueWeek ? playerLeagueWeek.minWeekRequired : league.minMatchesPerWeek}
                    marks={marks}
                />
            </div>
            <span
                className=" text-left text-max-matches">Số trận tối đa: {playerLeagueWeek ? playerLeagueWeek.numberPlayedMatchInWeek : 0}/{league.maxMatchesPerWeek}</span>
            <div className="absolute top-0 right-0 label-text rounded-tr-lg rounded-bl-lg px-2 py-1"><span className="text-white text-bold">{labelText}</span></div>
        </div>

    </div>
}

export default PlayerLeagueProgress