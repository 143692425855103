import moment from "moment";

export default interface LeagueInterface {
  id: string;
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  name: string;
  formId: string | null;
  prize: string;
  leaguePrizes: Array<LeaguePrizeInterface> | null | undefined;
  fee: number;
  logo: string | null;
  banner: string;
  numberParticipants: number;
  rule: string;
  type?: string;
  startDate: string;
  endDate: string;
  startRegistrationDate: string;
  endRegistrationDate: string;
  startDateObj: any;
  endDateObj: any;
  startRegistrationDateObj: any;
  endRegistrationDateObj: any;
  startPairingDate: string,
  endPairingDate: string,
  startPairingDateObj: any;
  endPairingDateObj: any;
  minWeekReachMinMatchesRequired: number;
  minMatchesPerWeek: number;
  ranks: Array<{
    id: string,
    code: string,
    class: string
  }> | undefined;

  isVerified: boolean;
  venues: Array<{
    id: string;
    address: string;
    name: string;
  }> | null;
}

export const defaultLeague: LeagueInterface = {
  id: "",
  name: "",
  startDate: "",
  startDateObj: moment(),
  endDate: "",
  endDateObj: moment(),
  rule: "",
  prize: "",
  leaguePrizes: undefined,
  banner: "",
  type: "",
  createdAt: "",
  createdBy: "",
  endRegistrationDate: "",
  endRegistrationDateObj: undefined,
  fee: 0,
  formId: null,
  isVerified: false,
  lastModifiedAt: "",
  lastModifiedBy: "",
  logo: "",
  numberParticipants: 0,
  startRegistrationDate: "",
  startRegistrationDateObj: undefined,
  venues: null,
  minWeekReachMinMatchesRequired: 0,
  minMatchesPerWeek: 0,
  startPairingDateObj: undefined,
  endPairingDateObj: undefined,
  ranks: undefined,
  startPairingDate: '',
  endPairingDate: ''
};

export interface BracketRoundDataInterface {
  createdAt: string;
  createdBy: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  id: string;
  leagueId: string;
  roundId: string;
  leaguePlayerId: string;
  playerId: string;
  playerName: string;
  secondLeaguePlayerId: string;
  secondPlayerId: string;
  secondPlayerName: string;
  leagueEliminationRoundId: string;
  preLeagueScheduleId: string;
  hasResult: false;
  leagueMatchId: string;
  position: 1;
  roundStep: 1;
  winnerId: string;
  playerScore: number;
  secondPlayerScore: number;
  leagueMatch: any;
}

export interface BracketLeagueDetailInterface {
  league: {
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    id: string;
    name: string;
    formId: null;
    type: string;
    prize: null;
    fee: number;
    logo: null;
    banner: string;
    numberParticipants: number;
    rule: string;
    startDate: string;
    endDate: string;
    startRegistrationDate: string;
    endRegistrationDate: string;
    isActive: true;
    maxMatchesPerWeek: number;
    rivalChallengeCoolDownTimeInDays: number;
    minMatchesPerWeek: number;
    minWeekReachMinMatchesRequired: number;
  };
  totalRound: 6;
  roundData: {
    roundName: string;
    data: BracketRoundDataInterface[];
  }[];
}

export interface LeaguePrizeInterface {
  createdAt: string,
  createdBy: string,
  id: string,
  label: string,
  prizeInCash:string,
  prizeInKind:string,
  lastModifiedAt:string,
  lastModifiedBy:string
}