import NewLeagueListingItem from "components/league/NewListingItem";
import LeagueInterface from "interfaces/league";
import moment from "moment";
import { ReactNode, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  TETabs,
  TETabsContent,
  TETabsItem,
  TETabsPane,
} from "tw-elements-react";
import LeagueListingItem from "../../components/league/leagueListingItem";
import MiniGameListingItem from "../../components/league/MiniGameListingItem";
import * as SBService from "../../helpers/sportbookService";
import { CONSTANT } from "../../shared/constant";
import {
  bottomBarState,
  loaderState,
  showBottomBarState,
  userState,
} from "../../states/common";

const CustomTabPane = ({
  children,
  isActive,
  onClick,
}: {
  children: ReactNode;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className={`text-[12px] rounded-sm px-1 ${
        isActive ? "bg-[#1CB4A3] text-white" : "text-[#848484]"
      }`}
    >
      {children}
    </div>
  );
};

const RenderLeague = ({
  leagues,
  listJoinedId,
}: {
  leagues: LeagueInterface[];
  listJoinedId: string[];
}) => {
  return (
    <>
      {leagues?.map((league: any, index) => {
        return (
          <LeagueListingItem
            key={`league-listing-item-${index}`}
            isShowScanBtn={false}
            isJoined={listJoinedId.includes(league.id)}
            league={league}
          />
        );
      })}
    </>
  );
};

const LeagueListingPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [, setLoader] = useRecoilState(loaderState);
  const [, setActive] = useRecoilState(bottomBarState);
  const [bottomBar, setBottomBar] = useRecoilState(showBottomBarState);
  const [leagues, setLeagues] = useState<LeagueInterface[]>([]);
  const [miniGames, setMiniGames] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [filter, setFilter] = useState({
    page: 1,
    limit: CONSTANT.limit,
  });
  const [user, _] = useRecoilState(userState);

  const [currentTab, setCurrentTab] = useState("league");

  useEffect(() => {
    setActive("league");
    setBottomBar(true);
    loadAllLeague(currentTab);
  }, []);

  const listJoinedId = useMemo(
    () => (user.leagues ? user.leagues.map((league) => league.id) : ""),
    [user]
  );

  const loadAllLeague = async (tab = "league") => {
    if ((filter.page === 1 && leagues.length === 0) || filter.page < maxPage) {
      setLoader(true);
      try {
        let data;
        if (tab === "minigame") {
          data = await SBService.getMiniGame(filter);
        } else {
          data = await SBService.getLeagues(filter);
        }

        if (data) {
          if (data.hasNextPage) {
            setMaxPage(maxPage + 1);
            setFilter({ ...filter, page: data.page });
          } else {
            setMaxPage(1);
          }
          if (tab === "minigame") {
            setMiniGames(miniGames.concat(data.data));
          } else {
            setLeagues(leagues.concat(data.data));
          }
        }
      } catch (e: any) {
        if (e.response && e.response.data && e.response.data.error) {
          toast("Có lỗi xảy ra: " + e.response.data.error);
        } else {
          toast("Có lỗi xảy ra: " + e.message);
        }
      }
      setLoader(false);
    }
  };

  const handleBasicClick = (tab: string) => {
    setCurrentTab(tab);
    loadAllLeague(tab);
  };

  const [ended, setEnded] = useState<LeagueInterface[]>([]);
  const [inprogress, setinprogress] = useState<LeagueInterface[]>([]);

  useEffect(() => {
    if (leagues.length > 0) {
      setEnded(leagues.filter((league) => moment().isAfter(league.endDateObj)));
      setinprogress(
        leagues.filter((league) => moment().isBefore(league.endDateObj))
      );
    }
  }, [leagues]);

  return (
    <div>
      <h2 className="text-3xl uppercase pt-8 w-full text-center">
        danh sách giải đấu
      </h2>
      <div className="bg-[#1E1E32]">
        <div className="league-content-tab rounded-xl bg-[#1E1E32] w-full mt-5">
          <TETabs className="w-full mt-2">
            <TETabsItem
              wrapperClass={"w-full bg-[#1E1E32]"}
              className="w-full py-3 customTab"
              onClick={() => handleBasicClick("league")}
              active={currentTab === "league"}
              tag="button"
              color="primary"
            >
              Giải đấu
            </TETabsItem>
            <TETabsItem
              wrapperClass={"w-1/2 bg-[#1E1E32]"}
              className="w-full py-3 hidden"
              onClick={() => handleBasicClick("minigame")}
              active={currentTab === "minigame"}
              tag="button"
              color="primary"
            >
              Mini Game
            </TETabsItem>
          </TETabs>
        </div>
        <TETabsContent className="text-[#B0B4BA] bg-[#1E1E32] -mt-2">
          <TETabsPane className="p-4 text-sm flex flex-col gap-3" show={true}>
            <div className="flex items-center mx-auto">
              <div className="p-1 flex gap-3 items-center border-[0.5px] border-white rounded-md w-auto">
                <CustomTabPane
                  isActive={activeTab === 0}
                  onClick={() => setActiveTab(0)}
                >
                  Đang diễn ra
                </CustomTabPane>
                <CustomTabPane
                  isActive={activeTab === 1}
                  onClick={() => setActiveTab(1)}
                >
                  Đã kết thúc
                </CustomTabPane>
              </div>
            </div>

            {(activeTab === 0 ? inprogress : ended)?.map(
              (league: any, index) => {
                return (
                  <NewLeagueListingItem
                    key={`league-listing-item-${index}`}
                    isShowScanBtn={false}
                    isJoined={listJoinedId.includes(league.id)}
                    league={league}
                  />
                );
              }
            )}
          </TETabsPane>
          <TETabsPane
            className="font-exo text-sm p-4"
            show={currentTab === "minigame"}
          >
            {leagues.length > 0 ? (
              leagues.map((league: any, index) => {
                return (
                  <MiniGameListingItem
                    key={`league-listing-item-${index}`}
                    isShowScanBtn={false}
                    isJoined={listJoinedId.includes(league.id)}
                    league={league}
                  />
                );
              })
            ) : (
              <></>
            )}
          </TETabsPane>
        </TETabsContent>
      </div>
    </div>
  );
};

export default LeagueListingPage;
