import {QrScanner} from "@yudiel/react-qr-scanner";
import React, {useEffect, useState} from "react";
import {Outlet, redirect, useNavigate} from "react-router-dom";
import Navbar from "../../components/navbar";
import BottomBar from "../../components/bottomBar";
import Loader from "../../components/loader";
import {useRecoilState} from "recoil";
import {loaderState, userState} from "../../states/common";
import * as SBService from "../../helpers/sportbookService";
import * as _ from "lodash";
import toast from "react-hot-toast";
import moment, { ISO_8601 } from "moment";

const RankingHistory = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useRecoilState(loaderState)
    const [user, setUser] = useRecoilState(userState)
    const [showModal, setShowModal] = useState(false);
    const [experts, setExperts] = useState([{}])
    const [filter, setFilter] = useState(
        {
            page: 1,
            limit: 20
        }
    )

    const [totalPage, setTotalPage] = useState(1)

    const [histories, setHistories] = useState<any>([])

    useEffect(() => {
        const loadUser = async () => {
            try {
                setLoader(true)
                const historyData = await SBService.getProfileRankingHistory(filter);
                if (historyData && historyData.data) {
                    setHistories(historyData.data)
                    if (historyData.hasNextPage) {
                        setTotalPage(filter.page  +1)
                    }
                }
                setLoader(false)

            } catch (e: any) {
               console.log(e)
            }
        }
        if (!_.isEmpty(user.id)) {
            loadUser()
        }
    }, [])

    const loadMore = async () => {
        if (filter.page < totalPage && !loader) {
            setLoader(true)
            filter.page += 1;

            const historyData = await SBService.getProfileRankingHistory(filter);
            if (historyData && historyData.data) {
                setHistories(histories.concat(historyData.data))
                setFilter(filter)
            }
            setLoader(false)
        }
    }




    return <div className="w-full">
        <Navbar title="LỊCH SỬ ĐIỂM HẠNG"/>
        <div className="flex items-start space-x-4">
            <div className="flex-shrink-0">
                <img className="w-[92px] h-[92px] object-cover object-center rounded-full" src={user.profilePicture}
                     alt=""/>
            </div>
            <div className="flex-1 min-w-0 mt-2">
                <p className="text-2xl font-medium truncate">
                    {user.name}
                </p>
                <div className="flex items-center mt-3">
                    <div className="text-sm truncate text-white opacity-65 flex w-1/2">
                        <label className="text-gray-11 uppercase">Xếp hạng</label>
                        <span className="text-white ml-[6px] flex flex-wrap">{user.ranking.class}</span>
                    </div>
                    <div className="text-sm truncate text-white opacity-65 flex  w-1/2">
                        <label className="text-gray-11 uppercase">Điểm</label><span
                        className="text-white ml-[6px]">{user.score?.toString()}</span>
                    </div>
                </div>
            </div>
        </div>
        <hr className="mt-2 opacity-17 border-[#5E5E5E2B]"/>
        <p className="uppercase mb-2 mt-3 px-2">LỊCH SỬ ĐIỂM HẠNG</p>
        <div>
            {histories.length > 0 ? <div className="history-list">
                {histories.map((item: any) => {
                    return <div className="history-list-item w-full min-h-[1.75rem] pb-2 px-2" key={`history-${item.id}`}>
                        <div className="relative border-b border-b-gray-500 m-auto py-2">
                            <div className="w-3/4">
                                <p className="font-light text-sm leading-4 text-[#777B84]"> {moment(item.createdAt).format('DD/MM/YYYY HH:MM')}</p>
                                <p className="normal-case text-[0.9rem] text-gray-11 mt-1">{item.league ? `Thi đấu giải ${item.league.name}` : item.note}</p>
                            </div>
                            <div className="w-1/4 absolute text-lg font-exo font-bold leading-7 right-2 top-5 text-right">
                                {item.point > 0 ? <span className="text-[#65BA75]">+{item.point}</span> :
                                    <span className="text-[#EB9091]">{item.point}</span>}
                            </div>
                        </div>
                    </div>
                })}
                {totalPage > filter.page ? <p className="text-center mt-2">
                    <a className="inline-block w-full btn-primary accent-color w-full mt-3 text-center font-exo py-1 font-sm px-3" onClick={() => loadMore()}>Tải thêm</a>
                </p> : <></>}
            </div> : <></>}
        </div>
        {loader ? <Loader/> : <div/>}
    </div>
}

export default RankingHistory