import React, { useState, useEffect, useReducer } from "react";
import realtimeDB from "../helpers/firebase";
import { ref, get, set, onValue, update, remove } from 'firebase/database';
import { useRecoilState } from "recoil";
import { userState } from "../states/common";
import * as _ from "lodash";
import { getAssetFullUrl } from "../helpers/utils";
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TEModalFooter,
    TECarousel, TECarouselItem
} from "tw-elements-react";
import { useNavigate } from "react-router-dom";
import * as SBService from "../helpers/sportbookService";
import moment, { ISO_8601 } from "moment";
import toast from "react-hot-toast";

const GlobalNotification = () => {
    const [notification, setNotifications] = useState([]);
    const [user, setUser] = useRecoilState(userState)
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();
    let [competitor, setCompetitor] = useState<any>([])
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [league, setLeague] = useState({
        id: null,
        name: null
    })

    let [vsMatches, setVsMatches] = useState<any>([])
    let [players, setPlayers] = useState<any>([])

    let [getPlayersPage, setGetPlayersPage] = useState(1)
    let [isGetPlayersNextPage, setIsGetPlayersNextPage] = useState(false)

    useEffect(() => {
        if (user) {
            const listenPath = 'notifications/' + user.id;
            const messagesRef = ref(realtimeDB, listenPath);

            const getCompetitorById = async (playerId: any) => {
                try {
                    return await SBService.getUserById(playerId)
                } catch (error) {
                    console.log(error);
                }
                return false
            }

            const unsubscribe = onValue(messagesRef, async (snapshot) => {
                if (snapshot.exists() && snapshot.val() && snapshot.val().challengerId) {
                    const notifications = snapshot.val();
                    await getMatchDetail(notifications.matchId)
                    const playerData = await getCompetitorById(notifications.challengerId)
                    await setCompetitor(playerData.data);
                    await getLeagueData(notifications.leagueId, playerData.data.id)

                    if (!_.isEmpty(notifications)) {
                        //update(messagesRef, {})
                    }
                    setShowModal(true)

                    await remove(messagesRef)
                }
            });

            return () => {
                unsubscribe();
            };
        }
    }, [user]);

    const cancelChallenger = async () => {
        try {
            await SBService.denyChallenger(currentMatch.id)
            window.location.reload()
        } catch (e:any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    default:
                        toast.error('Lỗi, ' + e.response.data.error)
                        break;
                }
            } else {
                toast.error('Không thể từ chối trận thách đấu')
            }
        }
        setShowModal(false)
    }

    const getLeagueData = async (leagueId: any, competitorId: string) => {
        let leagueData
        const rsp = await SBService.getLeagueById(leagueId)
        leagueData = rsp
        if (rsp.data) leagueData = rsp.data
        if (leagueData) {
            if (leagueData.startDate) {
                leagueData.startDateObj = moment(leagueData.startDate, ISO_8601)
                leagueData.endDateObj = moment(leagueData.endDate, ISO_8601).endOf('day')
            }
            setLeague(leagueData)
            const rspParticipant = await SBService.getPlayerInLeagueData(leagueId, competitorId)
            if (rspParticipant && rspParticipant.data.playedMatches) {
                rspParticipant.data.playedMatches.forEach((match:any) => {
                    if (match.challengerId === user.id) {
                        match.challenger = user
                        match.acceptor = rspParticipant.data.player
                    } else {
                        match.challenger = rspParticipant.data.player
                        match.acceptor = user
                    }
                })
            }
            setVsMatches(rspParticipant.data.playedMatches)

            await loadPlayers(leagueId)
        }

    }

    const matchingWithAnotherPlayer = async (competitorId: string, matchId: string) => {
        // cancelChallenger()
        try {
            await SBService.acceptChallenger(matchId)
            navigate(`/league/${league.id}/competitor/${competitorId}/match/${matchId}`)
        } catch (e:any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    default:
                        toast.error('Lỗi, ' + e.response.data.error)
                        break;
                }
            } else {
                toast.error('Không thể chấp nhận trận thách đấu')
            }
        }
        setShowModal(false)

    }

    const [currentMatch, setCurrentMatch] = useState<any>({})
    const getMatchDetail = async (matchId: string) => {
        if (matchId) {
            try {
                const rsp = await SBService.getMatchById(matchId)
                if (rsp.data) {
                    setCurrentMatch(rsp.data)
                }
            } catch (error) {
                console.log(error);

            }
        }
    }



    const loadPlayers = async (leagueId:string, page: number = 1) => {
        const leaguePlayers = await SBService.getLeaguePlayersById(leagueId, 50, page)
        if (leaguePlayers.data) {
            if (page === 1) {
                players = leaguePlayers.data
            } else {
                players = players.concat(leaguePlayers.data)
            }

            setPlayers(players)
            if (leaguePlayers.hasNextPage) {
                setGetPlayersPage(leaguePlayers.page)
                setIsGetPlayersNextPage(leaguePlayers.hasNextPage)
            }
        }
        forceUpdate()
    }



    return (<>
        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center border border-[#084843] rounded">
                        <div className="text-gray-11 font-exo">Trận đấu chờ xác nhận</div>
                        <div className="text-[20px] font-medium truncate">{league.name}</div>
                        <div className="flex items-start space-x-4 mt-2">
                            <div className="flex-shrink-0">
                                <img className="w-[82px] h-[82px] object-cover object-center rounded-full" src={getAssetFullUrl(competitor?.profilePicture)}
                                    alt="" />
                            </div>
                            <div className="min-w-0">
                                <p className="text-[26px] text-left font-medium truncate">
                                    {competitor?.name}
                                </p>
                                <div className="truncate mt-3 text-white opacity-65 flex w-full">
                                    <div className="flex items-center justify-center">
                                        <label className="text-gray-11 uppercase">Trình độ</label>
                                        <div className="text-white ml-[6px] flex flex-wrap">{competitor?.ranking ?
                                            <div><span className="inline-block text-center w-full">{competitor.ranking.code}</span>
                                            </div> : '--'}</div>
                                    </div>
                                    <div className=" flex items-center justify-center ml-5">
                                        <label className="text-gray-11 uppercase">Điểm</label><span
                                            className="text-white ml-[6px]">{competitor?.score}</span>
                                    </div>
                                </div>
                                <div className="truncate mt-1 text-white opacity-65 flex w-full">
                                    <div className="flex items-center justify-center">
                                        <label className="text-gray-11 uppercase">Hạng</label>
                                        <div className="text-white ml-[6px] flex flex-wrap">{players ?
                                            <div><span className="inline-block text-center w-full">{players.find((pl: any) => pl.player.id == competitor.id)?.ranking}/{players.length}</span>
                                            </div> : '--'}</div>
                                    </div>
                                    <div className=" flex items-center justify-center ml-5">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-full divider mt-4  w-full" />
                        {!_.isEmpty(vsMatches) ? <TECarousel showControls ride="carousel" className="h-[56px] overflow-hidden">
                            {vsMatches.map((mt: any, index: number) => <div className="" key={mt.id}>
                                <TECarouselItem itemID={index + 1} className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                                    <p className="text-bold text-center font-exo">Lịch sử đối đầu <span className="text-[#0BD8B6]">({index + 1}/{vsMatches.length})</span></p>
                                    <div className="w-full flex font-exo text-gray-11 text-sm mt-2">
                                        <div className="w-1/2 flex justify-end  items-center px-2"><span className="px-2">{mt.acceptor?.name}</span> <img className="w-6 h-6 rounded-full text-center" src={getAssetFullUrl(mt.acceptor?.profilePicture)} /> <span className="px-2 font-bold">{mt.acceptorPoint}</span></div>
                                        <div className="w-1/2 flex justify-start items-center"><span className="px-2 font-bold">{mt.challengerPoint}</span> <img className="w-6 h-6 rounded-full  text-center" src={getAssetFullUrl(mt.challenger?.profilePicture)} /> <span className="px-2">{mt.challenger?.name}</span></div>
                                    </div>
                                </TECarouselItem>
                            </div>)}
                        </TECarousel> : ''}
                        {(currentMatch && currentMatch?.race > 0) ? <div className="mt-4 rounded w-full px-4 py-2 bg-[#0D1514] border border-[#084843]">
                            <h3 className="text-[#0BD8B6] uppercase font-medium text-sm font-exo">Thể thức thi đấu</h3>
                            <p className="text-[#FFCA16] font-exo text-sm font-medium">Chạm {currentMatch?.race} để thắng ({currentMatch?.race - 1}-{currentMatch?.race - 1} hòa)</p>
                            {currentMatch.handicappingPoint < 0 ? <p className="text-[#FFCA16] font-exo text-sm font-medium"><span className="text-[#0BD8B6]">{currentMatch?.acceptor?.name}</span> chấp <span className="text-[#0BD8B6]">{currentMatch?.challenger?.name}</span> {Math.abs(currentMatch.handicappingPoint)} ván</p>
                                : <p className="text-[#FFCA16] font-exo text-sm font-medium"><span className="text-[#0BD8B6]">{currentMatch?.challenger?.name}</span> chấp <span className="text-[#0BD8B6]">{currentMatch?.acceptor?.name}</span> {Math.abs(currentMatch.handicappingPoint)} ván</p>}
                        </div> : ''}
                        <div className="flex">
                            <button onClick={() => matchingWithAnotherPlayer(competitor?.id, currentMatch.id)}
                                className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-lg rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
                            >XÁC NHẬN
                            </button>
                            <button onClick={() => cancelChallenger()}
                                className="block w-full h-12 bg-transparent text-center uppercase text-lg text-[--dark-neutral-gray] rounded border-2 border-[#3B3B47]  mt-6 mr-2"
                            >Từ chối
                            </button>
                        </div>
                    </TEModalBody>

                </TEModalContent>
            </TEModalDialog>
        </TEModal>

    </>);
}

export default GlobalNotification