import React, { useEffect, useState, useReducer } from "react";
import LeagueInterface from "../../interfaces/league";
import moment, { ISO_8601 } from "moment";
import { useNavigate, useParams } from "react-router-dom";
import * as SBService from "../../helpers/sportbookService";
import { getAssetFullUrl } from "../../helpers/utils";
import * as _ from 'lodash'
import RankPlayerCard from "components/league/RankPlayerCard";
import MatchDetailCard from "../../components/league/MatchDetailCard";
import toast from "react-hot-toast";
import {CONSTANT} from "../../shared/constant";
import sportbookQR from 'statics/images/sportbookqr.jpg'


const LeaguePublic = () => {
    const { id } = useParams()
    const [address, setAddress] = useState('')
    const [players, setPlayers] = useState([{}])
    const [matches, setMatches] = useState<Array<any>>([])
    const [basicActive, setBasicActive] = useState("tab1");
    const now = moment();
    let [currentPage, setCurrentPage] = useState(1)
    let [isPlayerNextPage, setIsPlayerNextPage] = useState(false)
    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const [league, setLeague] = useState<LeagueInterface>({
        type: "",
        id: '',
        name: '',
        startDate: '',
        startDateObj: moment(),
        endDate: '',
        endDateObj: moment(),
        rule: '',
        prize: '',
        banner: '',
        createdAt: "",
        createdBy: "",
        endRegistrationDate: "",
        endRegistrationDateObj: undefined,
        fee: 0,
        formId: null,
        isVerified: false,
        lastModifiedAt: "",
        lastModifiedBy: "",
        logo: "",
        numberParticipants: 0,
        startRegistrationDate: "",
        startRegistrationDateObj: undefined,
        venues: null,
        minWeekReachMinMatchesRequired: 0,
        minMatchesPerWeek: 0,
        leaguePrizes: null,
        startPairingDate: '',
        endPairingDate: '',
        startPairingDateObj: undefined,
        endPairingDateObj: undefined,
        ranks: undefined,
    })

    useEffect(() => {

        const getLeagueData = async () => {
            try {
                let leagueData
                const rsp = await SBService.getPublicLeagueById(id)
                if (rsp && rsp.data) {
                    leagueData = rsp.data
                } else {
                    return
                }
                if (leagueData) {
                    if (leagueData.startDate) {
                        leagueData.startDateObj = moment(leagueData.startDate, ISO_8601)
                        leagueData.endDateObj = moment(leagueData.endDate, ISO_8601)
                        leagueData.startRegistrationDateObj = moment(leagueData.startRegistrationDate, ISO_8601)
                        leagueData.endRegistrationDateObj = moment(leagueData.endRegistrationDate, ISO_8601)
                    }
                    setLeague(leagueData)
                    await loadPlayers()
                    await loadMatches()
                    const interval = setInterval(async () => {
                        await loadPlayers()
                        await loadMatches()
                    }, 30000)

                }
                if (leagueData.venues) {
                    setAddress(leagueData.venues.map((venue: any) => venue.name).join(','))
                }

            } catch (e: any) {
                if (e.response && e.response.data && e.response.data.error) {
                    switch (e.response.data.error) {
                        default:
                            toast.error('Lỗi, ' + e.response.data.error)
                            break;
                    }
                } else {
                    toast.error('Không thể tìm thông tin giải đấu')
                }
            }
        }
        if (id) getLeagueData()
    }, [])

    const handleBasicClick = (value: string) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };


    const loadPlayers = async (page: number = 1) => {
        try {
            fetch(`${CONSTANT.baseUrl}v1/public/leagues/${id}/participants?limit=50&page=1`)
                .then(response => response.text())
                .then((result:any) => {
                    const rsp = JSON.parse(result)
                    setPlayers(rsp.data)
                })
                .catch(error => console.log('error', error));

        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    default:
                        toast.error('Lỗi, ' + e.response.data.error)
                        break;
                }
            } else {
                toast.error('Không thể tải danh sách cơ thủ tham gia giải đấu')
            }
        }
        forceUpdate()
    }

    const loadMatches = async (page = 1) => {
        try {
            fetch(`${CONSTANT.baseUrl}v1/public/leagues/${id}/matches?limit=50&page=1`)
                .then(response => response.text())
                .then((result:any) => {
                    const rsp = JSON.parse(result)
                    setMatches(rsp.data)
                })
                .catch(error => console.log('error', error));
        } catch (e: any) {
            if (e.response && e.response.data && e.response.data.error) {
                switch (e.response.data.error) {
                    default:
                        toast.error('Lỗi, ' + e.response.data.error)
                        break;
                }
            } else {
                toast.error('Không thể tải danh sách các trận đấu trong giải')
            }
        }

    }

    useEffect(() => {
        if (isPlayerNextPage) {
            loadPlayers(currentPage + 1)
        }
    }, [isPlayerNextPage])

    return <div className="w-screen h-screen flex bg-[#151522] font-exo text-white overflow-hidden" style={{background: '#151522'}}>
        <div className="w-1/3 p-3">
            {(league && league.banner) ? <div>
                <img className="rounded w-full" src={getAssetFullUrl(league.banner)} />
            </div> : ''}
            <div className="mt-3">
                <h1 className="text-[23px] text-white font-bold" style={{color:'#fff'}}>{league.name}</h1>
            </div>
            <div className="flex justify-start content-start text-[#B0B4BA] mt-4" style={{color: '#B0B4BA'}}>
                {league.startDateObj && <div className="flex"><p className="w-[82px] font-semibold">Thời gian: </p><span>{league.startDateObj.format('DD/MM/YY')} - {league.endDateObj.format('DD/MM/YY')}</span></div>}

            </div>
            <div className="bg-[#1E1E32] text-[14px] mb-6 rounded-lg " style={{background:'#1E1E32'}}>
                <div className="text-center p-3" style={{color: '#fff'}}>Giải thưởng</div>
                <hr className="bg-[#0BD8B6] border-none h-1" />
                <div className="pt-1 p-2">
                    <div className="font-exo leading-normal text-justify prize" style={{color: '#fff'}} dangerouslySetInnerHTML={{ __html: league.prize }} />
                </div>
                <div className="w-full mt-2 flex justify-center items-center mt-10">
                    <img className="w-1/3" src={sportbookQR} />
                </div>
            </div>
        </div>
        <div className="w-1/3 px-3">
            <div className="text-[16px] font-bold p-3" style={{color: '#fff'}}>BẢNG XẾP HẠNG</div>
            <div className="bg-[#1E1E32] text-[14px] text-[#B0B4BA] mb-6 rounded-lg "  style={{background:'#1E1E32', color: '#B0B4BA'}}>
                <div className="flex mb-3 w-full font-bold px-3 pt-3">
                    <div className="w-[30%] flex">
                        <div className="w-1/2 text-center">#</div>
                        <div className="w-1/2 text-center">Điểm</div>
                    </div>
                    <div className="w-7/12 pl-2">VĐV</div>
                    <div className="w-1/12">Hạng</div>
                </div>
                <hr className="border-[#5E5E5E]" />
                <div className="pl-2 h-[calc(100vh-120px)] overflow-auto">
                    {!_.isEmpty(players) && !_.isEmpty(players[0]) ? (players.map((player, index) => {
                        return <RankPlayerCard key={`player-leagues-${index}`} player={player} index={index} />
                    })) : ''}
                </div>
            </div>
        </div>
        <div className="w-1/3 px-3">
            <div className="text-[16px] font-bold p-3" style={{color: '#fff'}}>TRẬN ĐẤU VỪA DIỄN RA</div>
            <div className="bg-[#1E1E32] text-[14px] text-[#B0B4BA] mb-6 rounded-lg"  style={{background:'#1E1E32', color: '#B0B4BA'}}>
                <div className="flex mb-3 w-full font-bold px-3 pt-3">
                    <div className="w-8/12">VĐV</div>
                    <div className="w-2/12 text-center">Hạng</div>
                    <div className="w-2/12 text-center">Tỷ số</div>
                </div>
                <hr className="border-[#5E5E5E] " />
                <div className="pl-2 h-[calc(100vh-120px)] overflow-auto">
                    {!_.isEmpty(matches) && !_.isEmpty(matches[0]) ? (matches.map((match, index) => {
                        return <MatchDetailCard key={`match-league-${index}`} match={match} />
                    })) : ''}

                </div>

            </div>
        </div>

    </div>
}

export default LeaguePublic