import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as _ from 'lodash'
import * as SBService from "../../helpers/sportbookService";
import {useRecoilState} from "recoil";
import {bottomBarState, loaderState, showBottomBarState, userState} from "../../states/common";
import toast from "react-hot-toast";
import moment, {ISO_8601} from "moment";
import 'moment/locale/vi'
import Navbar from "../../components/navbar";

import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalBody,
    TEModalFooter,
} from "tw-elements-react";
import {currencyToNumber, file2Base64, getAssetFullUrl, toCurrency} from "../../helpers/utils";
import Icon from "../../components/icon";

const LeagueMatchingSubmitPage = () => {
    const [user, setUser] = useRecoilState(userState)
    const [currentMatch, setCurrentMatch] = useState<any>({})
    const [, setLoader] = useRecoilState(loaderState)
    const [league, setLeague] = useState({
        id: null,
        name: null
    })
    const navigate = useNavigate()
    const [referees, setReferees] = useState<any>([])

    const [showModal, setShowModal] = useState(false);
    const [player, setPlayer] = useState({
        id: null,
        name: null,
        profilePicture: '',
        playerRankInLeague: 10,
        leagueScore: 40,
        ranking: {code: '', class: ''},
        score: 2910
    })
    const [challenger, setChallenger] = useState({
        id: null,
        name: null,
        profilePicture: '',
        playerRankInLeague: 11,
        leagueScore: 38,
        ranking: {code: '', class: ''},
        score: 2800
    })

    const [results, setResults] = useState<any>([])
    const {leagueId, matchId} = useParams()

    const [challengerScore, setChallengerScore] = useState<any>('')
    const [playerScore, setPlayerScore] = useState<any>('')
    const [billPhoto, setBillingPhoto] = useState<string | null>(null)

    const handleUploadIMG = async ( e: any) => {
        const file = e.target.files[0]
        if (file) {
            let img64
            img64 = await file2Base64(file) + ''
            if (img64) {
                setBillingPhoto(img64)
            }
        }
    }


    useEffect(() => {
        document.title = "Kết quả trận đấu"
        setLoader(true)
        if(matchId) {            
            loadMatchDetail()
        } else {            
            navigate(-1)
        }
        //loadCompetitionData()
    }, [])

    const loadMatchDetail = async () => {        
        if(matchId){
            try {
                const rsp = await SBService.getMatchById(matchId)
                if(rsp.data) {
                    if (rsp.data.league) {
                        if (rsp.data.league.startDate) {
                            rsp.data.league.startDateObj = moment(rsp.data.league.startDate, ISO_8601)
                            rsp.data.league.endDateObj = moment(rsp.data.league.endDate, ISO_8601)
                        }
                        setLeague(rsp.data.league)
                        await getRefereeAvailable(rsp.data.league.id)
                    }
                    setCurrentMatch(rsp.data)
                    setPlayer(rsp.data.acceptor)
                    setChallenger(rsp.data.challenger)
                }
            } catch (error) {
                console.log(error);
                
            }
        }
        setLoader(false)
    }

    const getRefereeAvailable = async (leagueId:string) => {
        const rsp = await SBService.getLeagueReferees(leagueId)
        if(rsp.data){
            setReferees(rsp.data)
        }
    }

    const validInputChallengerScore = (event:any) => {
        event.preventDefault()
        let val = event.target.value;
        if (val) {
            if (val > currentMatch.race) {
                val = currentMatch.race
            }
            if (val < 0) {
                val = 0;
            }
            setChallengerScore(val)
        } else {
            setChallengerScore("")
        }

    }

    const validInputPlayerScore = (event:any) => {
        event.preventDefault()
        let val = event.target.value;
        if (val) {
            if (val > currentMatch.race) {
                val = currentMatch.race
            }
            if (val < 0) {
                val = 0;
            }
            setPlayerScore(val)
        } else {
            setPlayerScore("")
        }
    }

    const handleSubmitMatch = async () => {
        if(playerScore && challengerScore){
            setLoader(true)
            if (playerScore === challengerScore === currentMatch.race) {
                toast.error('Số điểm không hợp lệ')
                setLoader(false)
                return
            }
            if (!billPhoto && !currentMatch.billPhoto) {
                toast.error('Chưa cập nhật hình ảnh hóa đơn')
                setLoader(false)
                return
            }
            let dataSubmit = {
                "challengerPoint": Number(challengerScore),
                "acceptorPoint": Number(playerScore),
                "challengerId": challenger.id,
                "acceptorId": player.id,
                "billPhoto": billPhoto ? billPhoto.split(',')[1] : null,
                "billAmount": 0
              }
              try {
                const rsp = await SBService.submitMatch(currentMatch.id, dataSubmit)
                  setLoader(false)
                if(rsp){
                    setShowModal(true)
                }
              } catch (e:any) {
                  if (e.response && e.response.data && e.response.data.error) {
                      if (e.response.data.error === "pointNotMatchedToRace") {
                          toast.error('Chưa đủ số điểm chạm: '+ currentMatch.race)
                      } else {
                          toast.error('Có lỗi xảy ra, vui lòng quay trở lại sau')
                      }
                  } else {
                      toast.error('Lỗi cập nhật trận đấu!')
                  }
                  setLoader(false)

              }
        } else {
            toast('Vui lòng nhập điểm của trận đấu!') 
        }

    }

    return <div className="py-6">
        <Navbar title={"KẾT QUẢ TRẬN ĐẤU"}/>
        <h1 className="font-exo text-sm font-medium text-gray-11 mb-4 bg-[#2D2D46] rounded p-3">{league.name}</h1>
        <div className="mt-4">
            <select className="w-full p-2 rounded font-exo text-sm mb-2 bg-[#2D2D46] text-gray-11" hidden>
                <option key={'referee-null'} value="">Chọn trọng tài</option>
                {!_.isEmpty(referees) ? referees.map((r: any) => {
                    return <option key={r.id} value={r.id}>{r.name}</option>
                }) : ''}
            </select>
        </div>
        <div className="mt-4 py-3 px-4 flex bg-[#1E1E32] rounded-[1rem]">
            <div className="w-[calc(100%-80px)] pr-4 font-exo text-gray-11">
                <div className="flex justify-start items-center">
                    <img className="w-[32px] h-[32px] rounded-full" src={getAssetFullUrl(challenger.profilePicture)}/>
                    <span className="text-white ml-2 uppercase">{challenger.name}</span>
                </div>
                <div className="flex flex-wrap text-sm mt-2">
                    <div className="w-[60%] pr-4 hidden">
                        <p>XH mùa giải: {challenger ? challenger.playerRankInLeague : ''}</p>
                    </div>
                    <div className="w-[40%] hidden">
                        <p>Điểm: {currentMatch?.challengerLeaguePlayer?.ranking || '--'}</p>
                    </div>
                    <div className="w-[60%] pr-4">
                        <p>Trình độ: {challenger && challenger.ranking ? challenger.ranking.class : '--'}</p>
                    </div>
                    <div className="w-[40%]">
                        <p>Điểm: {challenger.score}</p>
                    </div>
                </div>


            </div>
            <div className="w-[80px]">
                <input type="number" className="bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center
                 w-[83px] h-[72px] bg-main text-[40px] py-3 px-2" value={challengerScore}
                       onChange={event => validInputChallengerScore(event)}/>
            </div>
        </div>

        <div className="mt-4 py-3 px-4 flex bg-[#1E1E32] rounded-[1rem]">
            <div className="w-[calc(100%-80px)] pr-4 font-exo text-gray-11">
                <div className="flex justify-start items-center">
                    <img className="w-[32px] h-[32px] rounded-full" src={getAssetFullUrl(player.profilePicture)}/> <span
                    className="text-white ml-2 uppercase">{player.name}</span>
                </div>
                <div className="flex flex-wrap text-sm mt-2">
                    <div className="w-[60%] pr-4 hidden">
                        <p>XH mùa giải: {player ? player.playerRankInLeague : ''}</p>
                    </div>
                    <div className="w-[40%] hidden">
                        <p>Điểm: {currentMatch?.acceptorLeaguePlayer?.ranking || '--'}</p>
                    </div>
                    <div className="w-[60%] pr-4">
                        <p>Trình độ: {player && player.ranking ? player.ranking.class : '--'}</p>
                    </div>
                    <div className="w-[40%]">
                        <p>Điểm: {player.score}</p>
                    </div>
                </div>
            </div>
            <div className="w-[80px]">
                <input type="number" className="bg-[#111C1B] border border-[#145750] rounded-[1rem] text-center 
                 w-[83px] h-[72px] bg-main text-[48px] py-3 px-2" value={playerScore}
                       onChange={event => validInputPlayerScore(event)}/>
            </div>
        </div>

        <div className="w-full px-4 mt-6 bg-[#1E1E32] rounded-[0.6rem] py-4 font-exo text-xs text-gray-11 relative">
            <div className="flex h-[50px] justify-between">
                <div>
                    <span className="font-exo text-gray-11"> Bill thi đấu</span>
                </div>
                <label
                    className="mt-1 font-exo bg-[#0EB39E] text-white border-none rounded text-sm w-[50%] h-[36px] flex justify-center items-center"
                ><Icon icon="camera"/>
                    <span className="text-sm ml-3">Chụp lại bill</span>
                    <input className="hidden" type="file"
                           onChange={(e) => handleUploadIMG(e)}/>
                </label>
            </div>
            <div>
                {billPhoto ? <img className="block" src={billPhoto}/> : (currentMatch.billPhoto ? <img className="block" src={currentMatch.billPhoto}/> : '')}
            </div>
        </div>

        <button
            className="block w-full h-12 bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow mt-6 mr-2"
            onClick={() => handleSubmitMatch()}
        >Xác nhận tỉ số
        </button>

        <TEModal className="zIndexTop" show={showModal} setShow={setShowModal}>
            <TEModalDialog className="h-full" centered={true}>
                <TEModalContent>
                    <TEModalBody className="bg-[#1E1E32] text-center">
                        <h4 className="font-bold text-xl">
                            XÁC NHẬN THÀNH CÔNG
                        </h4>
                        <p className="text-left mt-4 font-exo">
                            Thông tin trận đấu đã được cập nhật, đối thủ và trọng tài sẽ xác nhận tỉ số và hệ thống sẽ
                            cập nhật kết quả cho bạn
                        </p>
                    </TEModalBody>
                    <TEModalFooter className="bg-[#1E1E32] flex justify-center border-none w-full">
                        <TERipple rippleColor="light">
                            <button
                                type="button" onClick={() => {
                                window.location.href = '/'
                            }}
                                className="w-full px-6 py-4 inline-block rounded  bg-[#0EB39E] text-center uppercase text-sm rounded border-2 border-[#ADF0DD] button-custom-shadow"
                            >
                                Quay về trang chủ
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    </div>;
};

export default LeagueMatchingSubmitPage;